import {animate, style, transition, trigger} from '@angular/animations';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild} from '@angular/core';
import {MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import { map, skip, take, takeUntil } from 'rxjs/operators';

import { ResourceAccessInfo } from '../../access_management/models/access_management.model';
import { AccessManagementActionsService } from '../../access_management/services/access_management_actions.service';
import { FeatureFlagService } from '../../feature_flag/feature_flag_service';
import {FirebaseAnalyticsService} from '../../firebase/firebase_analytics_service';
import {FirebasePerformanceService, Trace, TraceName} from '../../firebase/firebase_performance_service';
import {PluginService} from '../../plugin/plugin_service';
import {StagingService} from '../../right_panel/staging_service';
import {Original} from '../../services/asset_service';
import {PaginatorIntl} from '../../services/paginator-intl';
import {PreferencesService} from '../../services/preferences_service';
import {ProgressbarService} from '../../services/progressbar_service';
import {ResizeObserverService} from '../../services/resize_observer_service';
import {FacetGroup} from '../../services/search_facet_service';
import {SearchInputService, SearchMode, SearchType} from '../../services/search_input_service';
import {StateService} from '../../services/state_service';
import {TableUtils} from '../../services/table_utils';
import {DEFAULT_LIST_PAGE_SIZE, DisplayMode, GRID_PAGE_SIZE, PaddedSegment, VodSearchService} from '../../services/vod_search_service';
import {BatchOperationService} from '../../shared/batch_operation_service';

/**
 * The number of columns will change to allow at least this horizontal space
 * (content + margin) for each result card.
 */
const MIN_CARD_HORIZONTAL_SPACE = 264;

/** Page size options for list view. */
const PAGE_SIZE_OPTIONS = [30, 50, 100, 200];

const ALL_COLUMNS = [
  'select', 'title', 'duration', 'content-type', 'event-time', 'last-modified',
  'permission', 'location','action'
] as const;

const enum TableWidthBreakpoint {
  LARGE = 1250,
  MEDIUM = 800
}

/**Limit the request to 100 assets to avoid performance issues */
const LIMIT_OF_REQUEST_TO_RESTRICT_ASSETS = 100;

/**
 * VoD Search results in grid or list view
 */
@Component({
  selector: 'mam-search-results',
  templateUrl: './search-results.ng.html',
  styleUrls: ['./search-results.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: PaginatorIntl}],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger(
      'slideUpIn',
      [
        transition(
            ':enter',
            [
              style({position: 'relative', top: 30, opacity: 0.25}),
              animate('300ms ease-out', style({top: 0, opacity: 1}))
            ]),
      ])]
})
export class SearchResults implements OnDestroy, AfterViewInit {
  @Output() readonly facetsClear = new EventEmitter<void>();
  @ViewChild('scrollView') scrollView!: ElementRef<HTMLElement>;
  @ViewChild('searchResultsEl') searchResultsEl!: ElementRef<HTMLElement>;
  @ViewChild('centeredContentEl') centeredContentEl!: ElementRef<HTMLElement>;

  /** Visible table columns for list view. Changed based on screen size. */
  displayedColumns = [...ALL_COLUMNS];

  // Breakpoint when ScrollTop functionality changes based on layout,
  // for now it's 960 px when a persistent panel goes below search results
  scrollTopBreakpoint: number = 960;

  /**
   * Search request results. Contain padded segments for the red areas on each
   * card, and whether they are the result of the initial facets empty response.
   */
  readonly results$: Observable<PaddedSearchResponse|undefined>;

  readonly facetGroups$: Observable<FacetGroup[]|undefined>;

  readonly showClearFacetsButton$: Observable<boolean>;

  /** Indicates what search was used for the recent search. */
  readonly searchMode$: Observable<SearchMode|undefined>;

  readonly SearchMode = SearchMode;

  readonly PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  readonly isEnabledAccessManagement = this.featureService.featureOn('enable-access-management');
  private assets!: PaddedSegment[] | undefined;

  restrictedList: boolean = false;

  private readonly performanceTrace?: Trace;
  private readonly destroyed$ = new ReplaySubject<void>(1);

  constructor(
      private readonly host: ElementRef<HTMLElement>,
      readonly vodSearchService: VodSearchService,
      readonly pluginService: PluginService,
      readonly progressbar: ProgressbarService,
      readonly stateService: StateService,
      readonly tableUtils: TableUtils,
      private readonly resizeObserver: ResizeObserverService,
      private readonly analyticsService: FirebaseAnalyticsService,
      private readonly cdr: ChangeDetectorRef,
      private readonly batchOperationService: BatchOperationService,
      private readonly preferences: PreferencesService,
      private readonly featureService: FeatureFlagService,
      performanceService: FirebasePerformanceService,
      searchInputService: SearchInputService,
      readonly stagingService: StagingService,
      private readonly accessManagementActionsService: AccessManagementActionsService
  ) {
      this.performanceTrace = performanceService.startTrace(TraceName.SEARCH_RESULT_PAGE_READY);
      searchInputService.searchType$.next(SearchType.VOD);

      // Clear selected segments on stateService
      this.vodSearchService.selectedSegmentNames.clear();

      this.vodSearchService.pageChange$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
          // For new search or page change scroll to the top
          this.scrollTop();
      });

      // Converts results to padded segments bound in the template
      this.results$ = this.vodSearchService.searchResponse$.pipe(
          map((response) => {
              // Do not display anything until we have results. A `null` value is
              // received when the chips have been cleared.
              if (response === null) return undefined;
              const isInitialFacetsResponse = Boolean(response.isInitialFacetsResponse);
              this.performanceTrace?.stopAfter(response.videoSegments.length);
              this.analyticsService.logSearchEvent('VoD search');
              const segments = this.vodSearchService.withPadding(response.videoSegments);
              this.assets = segments;
              return { segments, isInitialFacetsResponse };
          })
      );

      // Updates Facets from search results *ONLY* when the query updated by input
      // box rather than facet select.
      this.facetGroups$ = this.vodSearchService.searchResponse$.pipe(
          map((response) => {
              if (!response || !response.facetGroups) return undefined;
              return response.facetGroups;
          })
      );

      // Shows clear facets button in the empty contents component only when there
      // are some facets selected and some facet groups returned.
      this.showClearFacetsButton$ = combineLatest([this.facetGroups$, this.vodSearchService.facetSelections$]).pipe(
          map(([facetGroups, facetSelections]) => !!facetGroups && facetSelections.length > 0)
      );

      this.searchMode$ = this.vodSearchService.searchResponse$.pipe(map((response) => response?.searchMode));

      // When search was done in SEGMENT mode, force switch UI to Grid view.
      this.searchMode$.pipe(takeUntil(this.destroyed$)).subscribe((mode) => {
          if (mode === SearchMode.SEGMENT) {
              this.vodSearchService.displayMode$.next(DisplayMode.GRID);
          }
      });
  }

  ngAfterViewInit() {
    // Update the number of columns when the available space for cards changes.
    this.resizeObserver.observe(this.searchResultsEl.nativeElement)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(searchResultsRect => {
          this.updateColumnsCount(searchResultsRect.width);
          this.updateVisibleColumnsInListView(searchResultsRect.width);
        });

    this.loadingFromAccessRestriction();
  }

  loadingFromAccessRestriction() {
    this.accessManagementActionsService.loading$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe({
      next: (loading) => {
        if(loading) {
          this.progressbar.show();
        }
      },
      error: () => this.progressbar.hide(),
      complete: () => this.progressbar.hide()
    });
  }

  /** Returns the middle of a result segment. */
  getThumbTime(result: PaddedSegment) {
    return result.startTime + (result.endTime - result.startTime) / 2;
  }

  formatInputPlaceholder(searchMode: SearchMode) {
    return searchMode === SearchMode.SEGMENT ? 'Enhanced results' :
                                               'Full video results';
  }

  /** Scrolls to the top of the page */
  scrollTop() {
    const scrollStrategy = screen.width <= this.scrollTopBreakpoint ?
      // works for mobile layout when a persistent panel goes below the search results: breakpoint-lg-max
      // (960 px and below)
              (element: HTMLElement) => element?.scrollIntoView() :
      // works for desktop layout when a persistent panel is on the right from search results
              (element: HTMLElement) => element?.scrollTo({top: 0, behavior: 'smooth'});

    scrollStrategy(this.scrollView?.nativeElement);
  }

  /** Handles event emitted when the paginator changes */
  onPageChange({previousPageIndex, pageIndex, pageSize}: PageEvent) {
    // Abandon performance trace if the page has changed.
    // It is either completed at this point or will log incorrect results.
    this.performanceTrace?.abort();

    // Page size is changed
    if (pageSize !== this.vodSearchService.pageSize$.value) {
      this.vodSearchService.pageSize$.next(pageSize);
      // Re-trigger search from the first page.
      this.vodSearchService.pageChange$.next(0);
      this.analyticsService.logSearchEvent(
          'Page size changed', {number1: pageSize});
      this.storePageSize(pageSize);
      return;
    }

    this.vodSearchService.pageChange$.next(pageIndex);

    if (pageIndex > (previousPageIndex ?? 0)) {
      this.analyticsService.logSearchEvent(
          'Next search result page', {number1: pageIndex});
    } else {
      this.analyticsService.logSearchEvent(
          'Previous search result page', {number1: pageIndex});
    }
  }

  /** Handles event when 'clear all filters' button is clicked */
  clearAllFacets() {
    this.facetsClear.emit();
  }

  onThumbnailLoaded() {
    this.performanceTrace?.maybeStop();
  }

  async addClipsToBins(segments: PaddedSegment[]) {
    await this.batchOperationService.addClipsToBinsWithConfirmation(
        segments.map(s => s.asset));
  }

  async edit(segments: PaddedSegment[]) {
    this.stateService.currentPersistentTab$.next('staging');
    await this.stagingService.edit(segments.map(s => s.asset));
  }

  exportOriginalAssets(segments: PaddedSegment[]) {
    this.batchOperationService.exportAssetsWithDialog(
        segments.map(s => s.asset));
  }

  async extendAssetsTtl(segments: PaddedSegment[]) {
    await this.batchOperationService.extendTtlWithDatePicker(
        segments.map(s => s.asset));
  }

  async deleteAssets(segments: PaddedSegment[]) {
    await this.batchOperationService.deleteAssetsWithConfirmation(
        segments.map(s => s.asset));
  }

  async purgeAssets(segments: PaddedSegment[]) {
    await this.batchOperationService.purgeAssetsWithConfirmation(
        segments.map(s => s.asset));
  }

  toggleViewMode(current: DisplayMode) {
    const nextMode =
        current === DisplayMode.GRID ? DisplayMode.LIST : DisplayMode.GRID;
    const gridPageSizeForRestrictList = this.vodSearchService.restrictedList ? LIMIT_OF_REQUEST_TO_RESTRICT_ASSETS : GRID_PAGE_SIZE;
    const pageSize =
        nextMode === DisplayMode.GRID ? gridPageSizeForRestrictList : this.restorePageSize();
    this.vodSearchService.pageSize$.next(pageSize);
    this.vodSearchService.pageChange$.next(0);

    // We want to keep the current mode while search results are loading for the new
    // mode. For that we skip(1) to ignore current value for
    // vodSearchService.searchResponse$ which is a ReplaySubject and react to
    // the next emission.
    this.vodSearchService.searchResponse$
        .pipe(takeUntil(this.destroyed$), skip(1), take(1))
        .subscribe(() => {
          this.vodSearchService.displayMode$.next(nextMode);
        });
  }

  isList(displayMode: DisplayMode) {
    return displayMode === DisplayMode.LIST;
  }

  isGrid(displayMode: DisplayMode) {
    return displayMode === DisplayMode.GRID;
  }

  select(segments: PaddedSegment[]) {
    this.vodSearchService.selectedSegmentNames.clear();
    for (const segment of segments) {
      this.vodSearchService.selectedSegmentNames.add(segment.name);
    }
  }

  isSelected(segment: PaddedSegment) {
    return this.vodSearchService.selectedSegmentNames.has(segment.name);
  }

  getSelectionInfo(segments: PaddedSegment[], selectedNames: Set<string>) {
    return this.tableUtils.getSelectionInfo(
        segments,
        selectedNames,
        seg => this.canBeSelected(seg),
    );
  }

  /**
   * Toggle segment selection if shift is not pressed.
   * Otherwise, toggles a block of items based on target asset and anchor asset.
   */
  toggleSelection(
      segment: PaddedSegment, allSegments: PaddedSegment[],
      shiftPressed = false) {
    const selectedNames = this.vodSearchService.selectedSegmentNames;

    const {itemsToSelect, itemsToUnSelect, anchorName} =
        this.tableUtils.processMultiSelect({
          items: allSegments,
          target: segment,
          selectedNames,
          shiftPressed,
          anchorName: this.selectionAnchorAssetName,
          canBeSelected: seg => this.canBeSelected(seg),
        });

    this.selectionAnchorAssetName = anchorName;

    for (const item of itemsToSelect) {
      selectedNames.add(item.name);
    }
    for (const item of itemsToUnSelect) {
      selectedNames.delete(item.name);
    }

    // Remove selection that can be caused by shift-clicking.
    document.getSelection()?.removeAllRanges();
  }

  trackSegment(index: number, segment: PaddedSegment) {
    return segment.name;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /** Asset that will be used for multi-select (shift+click) operations.  */
  private selectionAnchorAssetName = '';

  private canBeSelected({asset}: PaddedSegment) {
    return !asset.isDeleted;
  }

  private updateColumnsCount(availableWidth: number) {
    if (!availableWidth) return;
    // How many cards would fit in the available space.
    let count = Math.floor(availableWidth / MIN_CARD_HORIZONTAL_SPACE);
    // Ensure that it is a divider of the number of results so that all rows
    // are full given a complete page of results.
    while (GRID_PAGE_SIZE % count) count--;
    // Update the CSS to render this number of columns.
    this.host.nativeElement.style.setProperty('--columns-count', String(count));
  }

  private updateVisibleColumnsInListView(availableWidth: number) {
    this.cdr.markForCheck();

    // Large screen.
    if (availableWidth > TableWidthBreakpoint.LARGE) {
      this.displayedColumns = [...ALL_COLUMNS];
      return;
    }

    // Medium screen
    const visible = new Set<typeof ALL_COLUMNS[number]>(
        ['select', 'title', 'duration', 'event-time', 'location', 'permission','action']);

    // Small screen
    if (availableWidth < TableWidthBreakpoint.MEDIUM) {
      visible.delete('duration');
      visible.delete('event-time');
    }

    this.displayedColumns = ALL_COLUMNS.filter(c => visible.has(c));
  }

  private restorePageSize() {
    const pageSize = Number(this.preferences.load('search_list_page_size'));
    return PAGE_SIZE_OPTIONS.includes(pageSize) ? pageSize :
                                                  DEFAULT_LIST_PAGE_SIZE;
  }

  private storePageSize(pageSize: number) {
    this.preferences.save('search_list_page_size', String(pageSize));
  }

  updateAssetRestriction(segment: Partial<Original>, event?:Partial<ResourceAccessInfo>) {
    segment.permissions = event?.permissions;
    segment.permissionsDocumentId = event?.documentId;
  }

  filterRestricted(displayMode: DisplayMode) {
    const pageSize =
      displayMode === DisplayMode.GRID ? GRID_PAGE_SIZE : this.restorePageSize();
    const indexPage = this.vodSearchService.pagination.pageIndex;

    this.vodSearchService.pageSize$.next(this.vodSearchService.restrictedList ? LIMIT_OF_REQUEST_TO_RESTRICT_ASSETS : pageSize);
    this.vodSearchService.pageChange$.next(this.vodSearchService.restrictedList ? 0 : indexPage);
    this.cdr.detectChanges();
  }
}

interface PaddedSearchResponse {
  segments: PaddedSegment[];
  isInitialFacetsResponse: boolean;
}
