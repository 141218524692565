import { Pipe, PipeTransform } from '@angular/core';

import { PaddedSegment } from '../../services/vod_search_service';

@Pipe({
  name: 'hasRestrictions'
})

export class HasRestrictionsPipe implements PipeTransform {
  transform(videoSegments: PaddedSegment[]): boolean {
      return videoSegments.some(v => v.permissions);
  }
}
