import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, ReplaySubject, Subject, takeUntil } from 'rxjs';

import { StateService } from '../../services/state_service';
import { DisplayMode } from '../../services/vod_search_service';
import { ResourceTypes } from '../clip-bin-section/service/resource-types';
import { Resource, ResourceContent, ResourceService } from '../clip-bin-section/service/resource.service';

export interface ContentEvent {
    moved: boolean;
    deleted: boolean;
    id: string;
    name: string;
    retitled: string;
}

@Component({
    selector: 'mam-folder-content',
    templateUrl: './folder-content.component.html',
    styleUrls: ['./folder-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderContentComponent implements OnInit, OnDestroy {
    @Input() isAdmin: boolean = false;
    @Input() userEmail: string = '';

    @Input() folderId!: string;
    @Input() set displayMode(mode: DisplayMode) {
        this._displayMode = mode;
        this.cdr.detectChanges();
    }
    @Input() showAllFolders: boolean = false;

    get displayMode() {
        return this._displayMode;
    }

    private _displayMode: DisplayMode = DisplayMode.GRID;
    resourceContent: ResourceContent = {} as ResourceContent;
    hasContent = false;
    resultsLoading = true;
    skeletonItems = Array.from({ length: 6 });
    contentEvent$ = new Subject<void>();
    refreshFolderContent$ = new Subject<void>();

    contentRef$ = new BehaviorSubject<Resource[]>([]);

    get content() {
        return this.contentRef$.getValue();
    }

    private readonly destroyed$ = new ReplaySubject<void>(1);

    constructor(
        private cdr: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute,
        private resourceService: ResourceService,
        private stateService: StateService
    ) {}

    ngOnInit(): void {
        this.resultsLoading = true;
        this.cdr.markForCheck();

        combineLatest([this.route.params, this.route.queryParams])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(([params, queryParams]) => {
                this.folderId = params['folderId'];
                this._displayMode = queryParams['viewMode'] || DisplayMode.GRID;

                this.resultsLoading = true;
                this.cdr.markForCheck();

                this.resourceService
                    .getResourceChildren(ResourceTypes.FOLDER, this.folderId, { limit: 12, offset: 0 })
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe(() => this.getFolderContent());
            });
    }

    getFolderContent() {
        this.resourceService.currentContext$.pipe(takeUntil(this.destroyed$)).subscribe({
            next: (resourceContent: ResourceContent) => {
                this.resourceContent = resourceContent;
                this.hasContent = resourceContent?.parent?.directChildrenCount > 0;
                this.stateService.currentSelectedResource$.next(resourceContent);
                if (!this.hasContent) {
                    this.resultsLoading = false;
                    this.cdr.markForCheck();
                    return;
                }
                this.contentRef$.next(resourceContent.parent.children);
                this.resultsLoading = false;
                this.cdr.markForCheck();
            }
        });
        this.resourceService.pageChanged$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.resultsLoading = true;
            this.cdr.markForCheck();
        });
    }

    onContentEvent(content: ContentEvent) {
        if (content) {
            this.resourceService.paginationInfo$.next(this.resourceService.paginationInfo$.getValue());
        }
        this.cdr.markForCheck();
    }

    onFolderIconClick() {
        this.router.navigate(['.']);
        this.cdr.markForCheck();
    }

    onFolderCrumbClick(folderId?: string) {
        this.router.navigate(folderId ? [`/folders/${folderId}`] : ['/']);
        this.cdr.detectChanges();
    }

    trackBy(index: number, item: Resource) {
        return item.id;
    }

    isClipBin(folder: Resource) {
        return folder.type !== 'folder';
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
