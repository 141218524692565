import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '../../auth/auth_service';
import { environment } from '../../environments/environment';
import { ResourceBin } from '../clip-bin-preview/clip-bin-preview';
import { Resource } from '../clip-bin-section/service/resource.service';

export interface BreadcrumbItem {
    id: string;
    name: string;
}

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {
    private readonly BASE_URL = environment.resourcesApi;

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient
    ) {}

    getBreadcrumbs(id: string): Observable<BreadcrumbItem[]> {
        return this.httpClient.get<BreadcrumbItem[]>(`${this.BASE_URL}/folders/${id}/breadcrumb`, {
            headers: this.getAuthHeaders()
        });
    }

    private getAuthHeaders(): HttpHeaders {
        const accessToken = this.authService.getAccessToken();
        return new HttpHeaders({
            Authorization: `Bearer ${accessToken}`
        });
    }
}

@Component({
    selector: 'mam-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent implements OnInit {
    @Output() isExpandedChange = new EventEmitter<boolean>();
    @Input() resource: Resource | ResourceBin | undefined = undefined;
    @Input() breadcrumbPath: string | undefined = undefined;
    breadcrumbPathTrunc: string | undefined = undefined;
    hasChildren: boolean = false;
    isExpanded: boolean = false;
    folderLevel: number = 0;
    shouldTrunc: boolean = false;

    constructor(
        private readonly breadcrumbService: BreadcrumbService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        if (this.resource && this.resource?.directChildrenCount) {
            this.hasChildren = this.resource?.directChildrenCount > 0 || false;
        }
        this.folderLevel = this.resource?.level || 0;
    }

    onMouseEnter() {
        if (this.folderLevel === 0) return;
        if (!this.isExpanded) {
            this.toggleExpansion();
        }
    }

    onClick() {
        if (this.folderLevel === 0) return;
        this.isExpanded = !this.isExpanded;
        this.isExpandedChange.emit(this.isExpanded);
    }

    toggleExpansion(): void {
        this.isExpanded = true;
        this.isExpandedChange.emit(this.isExpanded);
        if (this.resource && this.resource.id) {
            this.breadcrumbService.getBreadcrumbs(this.resource.id || '').subscribe((breadcrumbs) => {
                this.breadcrumbPath = breadcrumbs.map((breadcrumb) => breadcrumb.name).join(' > ');
                this.breadcrumbPathTrunc = breadcrumbs.map((breadcrumb) => breadcrumb.name).join(' > ');
                this.shouldTrunc = this.breadcrumbPathTrunc.length > 17;
                this.cdr.markForCheck();
            });
        }
    }
}
