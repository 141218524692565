import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReplaySubject, takeUntil } from 'rxjs';

import { Clip } from '../../services/asset_service';
import { Bin } from '../../services/bin.service';
import { DisplayMode } from '../../services/vod_search_service';
import { ClipBinBinMoveDialog } from '../../shared/clipbin_bin_move_dialog/clipbin_bin_move_dialog';
import { ClipBinFolderDeleteDialog } from '../../shared/clipbin_folder_delete_dialog/clipbin_folder_delete_dialog';
import { ClipBinFolderMoveDialog } from '../../shared/clipbin_folder_move_dialog/clipbin_folder_move_dialog';
import { ClipBinFolderUpdateDialog } from '../../shared/clipbin_folder_update_dialog/clipbin_folder_update_dialog';
import { DeleteBinDialog, DeleteBinDialogData } from '../../shared/delete_bin_dialog';
import { RenameBinDialog } from '../../shared/rename_bin_dialog';
import { ResourceTypeApiName } from '../clip-bin-section/service/resource-types';
import { Resource } from '../clip-bin-section/service/resource.service';

type ResultItem = Resource | Bin | Clip;

@Component({
    selector: 'mam-cbo-list-display',
    styleUrl: './cbo-list-display.component.scss',
    templateUrl: './cbo-list-display.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CboListDisplayComponent implements OnDestroy, OnInit {
    @Input() result!: ResultItem[];
    @Input() selection!: Set<unknown>;
    @Output() toggleSelectionEvent = new EventEmitter();

    private readonly destroyed$ = new ReplaySubject<void>(1);

    displayedColumns: string[] = ['name', 'content', 'owner', 'location', 'menu'];
    isDisplayingBin: boolean = false;

    constructor(
        private dialog: MatDialog,
        private router: Router
    ) {}

    ngOnInit() {
        if (this.result && this.result.length > 0) {
            const firstItem = this.result[0];

            if (this.isResource(firstItem)) {
                this.isDisplayingBin = firstItem.type === 'clipbin';
            } else if (this.isBin(firstItem)) {
                this.isDisplayingBin = true;
                this.displayedColumns;
            }
        }

        if (this.isDisplayingBin) {
            const index = this.displayedColumns.indexOf('owner');
            if (index > -1) {
                this.displayedColumns.splice(index, 1);
            }
        }
    }

    isResource(obj: ResultItem): obj is Resource {
        return 'type' in obj && typeof obj.type === 'string';
    }

    isBin(obj: ResultItem): obj is Bin {
        return 'type' in obj && typeof obj.type === 'string';
    }

    isClip(obj: ResultItem): obj is Clip {
        return 'type' in obj && typeof obj.type === 'string';
    }

    get resourceResult(): Resource[] {
        return this.result as Resource[];
    }

    toggleSelect(resource: ResultItem) {
        this.toggleSelectionEvent.emit(resource);
    }

    executeAction(action: string, resource: Resource) {
        if (!resource.type || !this.ACTIONS_TARGET[resource.type]) {
            return;
        }
        this.ACTIONS_TARGET[resource.type][action](resource);
    }

    openShareBin(bin: Resource) {
        console.log('openShareBin', bin);
    }

    openRenameBin = (bin: Resource) => {
        this.dialog
            .open(RenameBinDialog, {
                ...RenameBinDialog.dialogOptions,
                data: { title: bin.name, name: bin.id }
            })
            .afterClosed()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((newTitle) => {
                if (!newTitle) {
                    return;
                }
                if ('title' in bin) {
                    bin.title = newTitle;
                }
            });
    };

    moveBinToFolder = (bin: Resource) => {
        this.dialog.open(ClipBinBinMoveDialog, {
            ...ClipBinBinMoveDialog.dialogOptions,
            data: bin
        });
    };

    openDeleteBin = (bin: Resource) => {
        this.dialog
            .open<DeleteBinDialog, DeleteBinDialogData>(DeleteBinDialog, {
                ...DeleteBinDialog.dialogOptions,
                data: { resource: bin }
            })
            .afterClosed()
            .pipe(takeUntil(this.destroyed$));
    };

    moveFolderToFolder = (folder: Resource) => {
        this.dialog.open(ClipBinFolderMoveDialog, {
            ...ClipBinFolderMoveDialog.dialogOptions,
            data: folder
        });
    };

    openDeleteFolder = (folder: Resource) => {
        this.dialog.open(ClipBinFolderDeleteDialog, {
            ...ClipBinFolderDeleteDialog.dialogOptions,
            data: folder
        });
    };

    openRenameFolder = (folder: Resource) => {
        this.dialog.open(ClipBinFolderUpdateDialog, {
            ...ClipBinFolderUpdateDialog.dialogOptions,
            data: folder
        });
    };

    navigateToFolder = (folder: Resource) => {
        if (folder.id) {
            const queryParams = { viewMode: DisplayMode.LIST };
            this.router.navigate(['/folders', folder.id], { queryParams, queryParamsHandling: 'merge' });
        }
    };

    navigateToClipBin = (bin: Resource) => {
        if (bin.id) {
            this.router.navigate(['/clipbin', bin.iasId, 'clip', 0], {
                queryParamsHandling: 'merge',
                queryParams: { type: 'bin' }
            });
        }
    };

    private ACTIONS_TARGET: Record<ResourceTypeApiName, Record<string, Function>> = {
        folder: {
            move: this.moveFolderToFolder,
            rename: this.openRenameFolder,
            delete: this.openDeleteFolder,
            navigate: this.navigateToFolder
        },
        clipbin: {
            delete: this.openDeleteBin,
            move: this.moveBinToFolder,
            rename: this.openRenameBin,
            navigate: this.navigateToClipBin
        }, //TODO: REVIEW BELOW RESOURCE TYPES ACCORDINGLY
        clip: {
            share: this.openShareBin
        },
        asset: {
            share: this.openShareBin
        }
    };

    ngOnDestroy() {
        // Unsubscribes all pending subscriptions.
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
