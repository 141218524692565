import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AuthService } from '../../auth/auth_service';
import { Clip } from '../../services/asset_service';
import { DisplayMode } from '../../services/vod_search_service';
import { ClipBinFolderDeleteDialog } from '../../shared/clipbin_folder_delete_dialog/clipbin_folder_delete_dialog';
import { ClipBinFolderMoveDialog } from '../../shared/clipbin_folder_move_dialog/clipbin_folder_move_dialog';
import { ClipBinFolderUpdateDialog } from '../../shared/clipbin_folder_update_dialog/clipbin_folder_update_dialog';
import { USER_ICON_PLACEHOLDER } from '../../shared/toolbar_menu';
import { Resource } from '../clip-bin-section/service/resource.service';

@Component({
  selector: 'mam-clip-bin-folder-preview',
  styleUrl: './folders-preview.component.scss',
  templateUrl: './folders-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClipBinFoldersPreview implements OnInit {
  @Input() isAdmin: boolean = false;
  @Input() userEmail: string = '';
  @Input() showAllFolders: boolean = false;
  @Input() folder!: Resource;
  @Input() viewMode: DisplayMode = DisplayMode.GRID;

  clips: Clip[] = [];

  userIconUrl = this.authService.getUserIcon() || USER_ICON_PLACEHOLDER;

  isLocationExpanded:boolean = false;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.getNestedAssets();
  }

  async getNestedAssets() {
    // this.clibinsFolderService.getFolderContent(this.folder.id).subscribe((result: FolderItemRef[]) => {
    //   result.forEach(folder => {
    //     if (this.clips.length >= 4) {
    //       return;
    //     }
    //     if (folder.binRefName != undefined) {
    //       this.assetService.searchClips(folder.binRefName, undefined, undefined, 4).subscribe(clipResult => {
    //         if (clipResult instanceof ErrorResponse) {
    //           return;
    //         }
    //         const clips = (clipResult as ListResponse<Clip>).assets;
    //         if (clips.length > 0 && this.clips.length < 4) {
    //           clips.forEach(clipEach => {
    //             if(this.clips.length > 3){
    //               return;
    //             }
    //             const foundClip = this.clips.find((clipFind) => clipFind.name === clipEach.name);
    //             if(!foundClip){
    //               this.clips.push(clipEach);
    //             }
    //           });
    //           this.cdr.markForCheck();
    //         }
    //       });
    //     }
    //   });
    // });
  }

  trackClip(index: number, clip: Clip) {
    return clip.name;
  }

  openRenameFolder() {
    this.dialog.open(ClipBinFolderUpdateDialog, {
      ...ClipBinFolderUpdateDialog.dialogOptions,
      data: this.folder,
    });
  }

  openDeleteFolder() {
    this.dialog.open(ClipBinFolderDeleteDialog, {
      ...ClipBinFolderDeleteDialog.dialogOptions,
      data: this.folder,
    });
  }

  openMoveFolder() {
    this.dialog.open(ClipBinFolderMoveDialog, {
      ...ClipBinFolderMoveDialog.dialogOptions,
      data: this.folder,
    });
  }

  navigateToFolder(folderId: string | undefined) {
    if (folderId) {
      this.router.navigate(['/folders', folderId], { queryParamsHandling: 'merge' });
    }
  }

  getAssetCount(folder: Resource): number {
    if (this.isLocationExpanded) return -1;
    return folder.directChildrenCount || 0;
  }

  onIsExpandedChange(isExpanded: boolean) {
    this.isLocationExpanded = isExpanded;
  }

}
