import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { take } from 'rxjs';

import { PermissionDetail } from 'access_management/models/access_management.model';
import { AccessManagementActionsService, RestrictionResult } from 'access_management/services/access_management_actions.service';
import { Asset } from 'services/asset_service';
import { SnackBarService } from 'services/snackbar_service';


export type Restriction = 'restrict' | 'public';

@Component({
  selector: 'mam-access-restriction-options',
  templateUrl: './access-restriction-options.component.html',
  styleUrl: './access-restriction-options.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessRestrictionOptionsComponent implements OnChanges {

  @Input() asset!: Asset;
  @Input() disabled: boolean | null = false;
  @Output() update = new EventEmitter<boolean>();

  documentId!: string;
  peopleAccess: PermissionDetail[] = [];
  selectedOption: Restriction = 'public';

  constructor(
    private readonly snack: SnackBarService,
    private readonly cdr: ChangeDetectorRef,
    private readonly accessManagementActions: AccessManagementActionsService
  ) {}


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['asset']?.currentValue)
      this.getRestriction();
  }


  openAccessManagement() {
    this.accessManagementActions.openAccessManagement(this.peopleAccess, this.documentId)
      .subscribe({
        next: () => this.getRestriction()
      });
  }

  openRestrictDialog() {
    const selected = 'restrict';

    if (selected === this.selectedOption) return;

    this.accessManagementActions.openRestrictDialog(this.asset)
      .subscribe({
        next: () => {
          this.updateSelectedOption(selected);
          this.snack.message('Success');
        }
      });
  }

  updateSelectedOption(selected: Restriction) {
    this.selectedOption = selected;
    this.update.next(true);
    this.getRestriction();
  }

  openPublicDialog() {
    const selected = 'public';
    if (selected === this.selectedOption) return;
    //Make Asset Public
    this.accessManagementActions.openPublicDialog(this.documentId)
      .subscribe({
        next: () => {
          this.updateSelectedOption(selected);
          this.snack.message('Success');
        }
      });
  }

  getRestriction() {
    this.accessManagementActions.getRestriction(this.asset)
      .pipe(take(1))
      .subscribe((value: RestrictionResult) => {
        this.documentId = value?.documentId ?? '';
        this.peopleAccess = value?.permissions ?? [];
        this.selectedOption = value?.restriction ?? 'public';
        this.cdr.detectChanges();
      });
  }
}
