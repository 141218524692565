<table mat-table [dataSource]="resourceResult" multiTemplateDataRows class="mat-elevation-z8">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox> </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
            <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? toggleSelect(element) : null"
                [checked]="selection.has(element)"
            >
            </mat-checkbox>
        </td>
    </ng-container>

    <!-- Name & Icon Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="name-header">Name</th>
        <td mat-cell *matCellDef="let element">
            <div class="name-container" (click)="executeAction('navigate', element)">
                @if (element.type === 'folder') {
                <mat-icon>folder</mat-icon>
                } @if (element.type === 'clip-bin') {
                <mat-icon role="img" aria-hidden="true"> subscriptions </mat-icon>
                }
                <span>{{ element.name }}</span>
            </div>
        </td>
    </ng-container>

    <!-- Content Column -->
    <ng-container matColumnDef="content">
        <th mat-header-cell *matHeaderCellDef>Content</th>
        <td mat-cell *matCellDef="let element">
            <div
                class="card-content-subtitle"
                [ngPlural]="(element.directChildrenCount) ? element.directChildrenCount : 0"
            >
                <ng-template ngPluralCase="=0">No items</ng-template>
                <ng-template ngPluralCase="=1">1 item</ng-template>
                <ng-template ngPluralCase="other">{{ element.directChildrenCount }} items</ng-template>
            </div>
        </td>
    </ng-container>

    <!-- Last Modified Column -->
    <ng-container matColumnDef="lastModified">
        <th mat-header-cell *matHeaderCellDef>Last Modified</th>
        <td mat-cell *matCellDef="let element">{{element.updatedAt | date:'mediumDate'}}</td>
    </ng-container>

    <!-- Owner Column -->
    <ng-container *ngIf="!isDisplayingBin" matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef>Owner</th>
        <td mat-cell *matCellDef="let element">
            <div class="element-owner">
                @if((element.ownerName && element.ownerName.length) || (element.owner && element.owner.length)){
                <span class="element-owner-icon"
                    >{{ (element!.ownerName || element!.ownerName!.length ? element.ownerName : element.owner
                    )![0].toUpperCase() }}</span
                >
                } @else {
                <mat-icon class="element-owner-icon" role="img" aria-hidden="true"> person </mat-icon>
                }

                <p class="element-owner-text" title="{{ element.createdBy }}">
                    {{ element.ownerName || element.ownerName?.length ? element.ownerName : element.owner }}
                </p>
            </div>
        </td>
    </ng-container>

    <!-- Location Column -->
    <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let element">
            <!-- Breadcrumb Icon -->
            <mam-breadcrumb [resource]="element"></mam-breadcrumb>
        </td>
    </ng-container>

    <!-- Menu Column -->
    <ng-container matColumnDef="menu" class="text-align-r">
        <th mat-header-cell *matHeaderCellDef class="text-align-r"></th>
        <td mat-cell *matCellDef="let element" class="menu-td">
          <div class="menu-wrapper">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
              </button>
          </div>
            <mat-menu #menu="matMenu" class="clip-bin-options-menu">
                <button
                    mat-menu-item
                    (click)="executeAction('rename', element)"
                    mam-firebase-ga="Open rename dialog"
                    [mam-firebase-resource]="element.id"
                >
                    Rename
                </button>
                <button
                    mat-menu-item
                    (click)="executeAction('move', element)"
                    mam-firebase-ga="Open move dialog"
                    [mam-firebase-resource]="element.id"
                >
                    Move
                </button>
                <button
                    mat-menu-item
                    (click)="executeAction('delete', element)"
                    class="delete-button"
                    mam-firebase-ga="Select deletion"
                    [mam-firebase-resource]="element.id"
                >
                    Delete
                </button>
            </mat-menu>
        </td>
    </ng-container>

    <!-- Header and Row Declarations -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
