import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { asyncScheduler, combineLatest, firstValueFrom, forkJoin, iif, of, ReplaySubject, scheduled } from 'rxjs';
import { concatMap, debounceTime, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { assertTruthy, castExists } from 'asserts/asserts';
import { ConfirmDialog, ConfirmDialogData } from 'confirm_dialog/confirm_dialog';
import { SharedLink } from 'models';


import { AuthService } from '../auth/auth_service';
import { FirebaseFirestoreDataService } from '../firebase/firebase_firestore_data_service';
import { ExpirationDaysOption, MAX_EXPIRATION_DAYS, NEVER_EXPIRED_TTL, SharedLinksService } from '../services/shared_links_service';
import { SnackBarService } from '../services/snackbar_service';
import { SharedLinkClipBinService, SharedLinkType } from '../shared_clipbin/services/shared_link_clipbin.service';

/** Dialog listing all shared links. */
@Component({
    selector: 'mam-shared-links-manager-dialog',
    templateUrl: './shared_links_manager_dialog.ng.html',
    styleUrls: ['./shared_links_manager_dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    animations: [
        trigger(
            'fadeProgressBar',
            [
                transition(
                    ':enter',
                    [
                        style({ opacity: 0 }),
                        animate('150ms ease-out', style({ opacity: 1 })),
                    ]),
                transition(
                    ':leave',
                    [
                        style({ opacity: 1 }),
                        animate('150ms ease-out', style({ opacity: 0 })),
                    ]),
            ]
        ),
    ]
})
export class SharedLinksManagerDialog implements OnDestroy {
    static readonly dialogOptions = {
        hasBackdrop: true,
        maxWidth: 660,
        width: '90%',
        // User must save to confirm deleting links, so we prevent accidental
        // closing of the dialog and force them to Cancel or Save.
        disableClose: true
    };

    /**
     * Visible list of links. `undefined` means that no result has been fetched
     * from the backend yet, while an empty array means that there is no link
     * matching the current query.
     */
    links?: SharedLink[];
    filteredLinks?: SharedLink[];

    /** Handles the type of links to show. */
    readonly sharedLinkTypes: SharedLinkType[] = [SharedLinkType.ALL, SharedLinkType.CLIPBINS, SharedLinkType.CLIPS];
    searchMode: SharedLinkType = SharedLinkType.ALL;

    /** Whether an API call to fetch links is in progress. */
    loading = false;

    /** List of link names to be revoked if we confirm the dialog. */
    revokeSet = new Set<SharedLink>();

    /** List of link names generated by clip bins. */
    linksFromClipbinsMap = new Set<string>();

    titleQuery = new UntypedFormControl();

    isEditExpiration: boolean = false;
    readonly expirationDaysOptions = this.sharedLinks.expirationDaysOptions;
    readonly clipBinExpirationDaysOptions = this.sharedLinkClipBinService.clipbinExpirationDaysOptions;

    constructor(
        private readonly dialog: MatDialog,
        private readonly sharedLinks: SharedLinksService,
        private readonly snackbar: SnackBarService,
        private readonly sharedLinkClipBinService: SharedLinkClipBinService,
        private readonly authService: AuthService,
        private readonly dataService: FirebaseFirestoreDataService,
        private readonly cdr: ChangeDetectorRef,
    ) {
        // Reset the list of links when the query changes (debounced).
        this.titleQuery.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                tap(() => {
                    this.debouncingQuery = true;
                }),
                debounceTime(500),
                startWith('')
            )
            .subscribe(() => {
                this.cdr.markForCheck();
                this.debouncingQuery = false;
                this.loadClipbinSharedLinkList();
                this.loadMore(true);
            });
    }

    /**
     * Whether the user just typed something in the search input which has not
     * yet been debounced.
     */
    private debouncingQuery = false;

    cancelChanges() {
      this.revokeSet.clear();
    }

    saveChanges() {
      if (!this.revokeSet.size) return;

      const linkNames = this.getLinksNamesIds();
      const revokeClipBins$ = this.getDocumentIdsToRevoke();
      const revokeSize = this.revokeSet.size;
      this.revokeSet.clear();
      this.loading = true;
      this.cdr.markForCheck();

      this.sharedLinks.revokeAll(linkNames)
          .pipe(
              concatMap((count: number) =>
                  iif(() => revokeClipBins$.length === 0,
                      of(count),
                      forkJoin(revokeClipBins$).pipe(concatMap(() => of(count)))
                  )
              )
          ).subscribe((revokedCount) => {
              if (revokedCount !== linkNames.length) {
                  this.snackbar.error(`Failed to revoke ${linkNames.length - revokedCount} links.`);
              } else {
                  this.snackbar.message(`Successfully revoked ${revokeSize} links.`);
              }

              this.loadClipbinSharedLinkList();
              this.loadMore(true);
          });
    }

    /**
     * Retrieves all clipbins items and create a list of Observable for be revoking
     *
     * @returns List of Observables for clipbins revoking
     */
    getDocumentIdsToRevoke() {
        const revokeClipBinsDocumentId = [...this.revokeSet]
            .filter(revoke => revoke.type === 'CLIPBIN')
            .map(c => c.documentId);

        return revokeClipBinsDocumentId.map((documentId) => scheduled(this.sharedLinkClipBinService.revokeClipbinShareLinkById(documentId), asyncScheduler));
    }

    /**
     * Get a list of links names (ids) that has revoke button selected, Clip and Clipbins will be retrieved
     *
     * @returns a list of names ids that has revoke selected
     */
    getLinksNamesIds(): string[] {
        return [...this.revokeSet].reduce((acc: string[], curr: SharedLink) => {
            if (curr.type !== 'CLIPBIN') {
                acc.push(curr.name);
                return acc;
            }

            const names = curr.clipSharedLinks?.map(c => c.assetName).flat();
            if (names) acc.push(...names);

            return acc;
        }, []);
    }

    getLinkUrl(link: SharedLink) {
        return link.type == 'CLIPBIN' ? link.url : this.sharedLinks.getLinkUrl(link);
    }

    loadClipbinSharedLinkList() {
        return this.sharedLinkClipBinService
            .retrieveActiveIASClipBinShareLinksByUser(this.authService.getUserEmail())
            .pipe(
                take(1),
                map((result) =>
                    result.map((item) => {
                        item.clipSharedLinks?.map((clip) => {
                            if (!this.linksFromClipbinsMap.has(clip.assetName)) {
                                this.linksFromClipbinsMap.add(clip.assetName);
                            }
                        });

                        return this.sharedLinkClipBinService.mapClipBinShareLinkToUIShareLink(item) || [];
                    })
                ),
                map((clips) => {
                    const clipsList = new Set();
                    return clips.filter((item) => {
                        const duplicate = clipsList.has(item.name);
                        clipsList.add(item.name);
                        return !duplicate;
                    });
                })
            );
    }

    /**
     * Load more links into the existing list. If no `pageSize` is given (for
     *  instance, after the query changes), a new list is started.
     */
    loadMore(isTypechange:boolean = false, pageSize?: number) {
        if (this.loading && !isTypechange) return;

        const resetList = pageSize == null;

        if (resetList) {
            this.nextPageToken = '';
            this.allLoaded = false;
            this.firstLoad = false;

            // Load enough rows to fill-up the dialog so that we can infinitely scroll.
            pageSize = Math.floor(window.innerHeight / 60);
        }

        if (this.allLoaded) return;

        this.loading = true;

        const initialSharedLinkList = this.sharedLinks.search(
            this.titleQuery.value || '',
            castExists(pageSize),
            this.nextPageToken
        ).pipe(switchMap((result) => of(result)));

        const clipBinShareLinkList = this.loadClipbinSharedLinkList().pipe(switchMap((result) => of(result)));

        const combinedClipBinsAndPlainSharedLinks = combineLatest([initialSharedLinkList, clipBinShareLinkList]).pipe(
          map(async ([initialList, clipBinList]) => {
                const initialClipSharedLinks = initialList
                    ? initialList.sharedLinks.filter((link) => !this.linksFromClipbinsMap.has(link.name))
                    : [];
                await this.formatClipSharedLinks(initialClipSharedLinks);

                const initialToken = initialList ? initialList.nextPageToken : null;

                // Filter clip bins by title
                if ((this.titleQuery?.value !== null) || (this.titleQuery?.value?.length > 0)) {
                    clipBinList = clipBinList.filter((link) => link.title.toLowerCase().includes(this.titleQuery.value.toLowerCase()));
                }

                const result = this.firstLoad
                    ? { nextPageToken: initialToken, sharedLinks: initialClipSharedLinks }
                    : { nextPageToken: initialToken, sharedLinks: [...clipBinList, ...initialClipSharedLinks] };

                this.firstLoad = true;
                return result;
            })
        );

        combinedClipBinsAndPlainSharedLinks.pipe(takeUntil(this.destroyed$)).subscribe(async (asyncResponse) => {
            const response = await asyncResponse;
            this.cdr.markForCheck();
            this.loading = false;

            if (resetList) {
                this.links = [];
                this.filteredLinks = [];
            }

            if (!response) {
                this.snackbar.error('Failed to load shared links');
                return;
            }

            if (response.nextPageToken) {
                this.nextPageToken = response.nextPageToken;
            } else {
                this.allLoaded = true;
            }

            assertTruthy(this.links, 'SharedLinksManagerDialog.loadMore: Links should be defined');
            this.links.push(...response.sharedLinks);
            this.filteredLinks = [...this.links];
            this.changeSearchMode(this.searchMode);
        });
    }

    private async formatClipSharedLinks(clipSharedLinks: SharedLink[]) {
      const neverExpiredLinks = new Set();
      const names = clipSharedLinks.map(link => link.name);
      if (names.length) {
        const neverExpiredData = await firstValueFrom(this.dataService.retrieveSharedLinkNeverExpired(names));
        neverExpiredData.forEach(item => neverExpiredLinks.add(item.data()['name']));
      }
      clipSharedLinks.forEach(link => {
        if (neverExpiredLinks.has(link.name)) {
          link.expireTime = NEVER_EXPIRED_TTL;
          link.originalTtl = 0;
          link.editableTtl = 0;
        } else {
          this.sharedLinks.formatExpireTime(link);
          link.originalTtl = MAX_EXPIRATION_DAYS;
          link.editableTtl = MAX_EXPIRATION_DAYS;
        }
      });
    }

    getMessage() {
        if (this.loading) {
            // Only show a loading message the first time we fetch links and not when
            // filtering by search query.
            return this.links == null ? 'Loading shared links...' : '';
        }

        if (this.links?.length || this.debouncingQuery) return '';

        return this.titleQuery.value ? 'No results found.' : 'No videos have been shared.';
    }

    /** Whether all links have been loaded and are displayed. */
    private allLoaded = false;

    private firstLoad = false;

    private nextPageToken = '';

    private readonly destroyed$ = new ReplaySubject<void>(1);

    getSearchModeText(type: SharedLinkType) {
        switch (type) {
            case SharedLinkType.CLIPBINS:
                return 'Shared clip bins';
            case SharedLinkType.CLIPS:
                return 'Shared clips';
            case SharedLinkType.ALL:
                return 'All shared links';
            default:
                return 'All shared links';
        }
    }

    changeSearchMode(type: SharedLinkType) {
        this.searchMode = type;

        if (type === SharedLinkType.CLIPBINS) this.filteredLinks = this.links?.filter((el) => el.type === 'CLIPBIN');
        else if (type === SharedLinkType.CLIPS) this.filteredLinks = this.links?.filter((el) => el.type !== 'CLIPBIN');
        else this.filteredLinks = this.links;
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    updateTtlClipbins(link: SharedLink) {
      link.isEditable = true;

      const data: ConfirmDialogData = {
          question: `Are you sure you want to update the Expiration days on ${link.type === 'CLIPBIN' ? 'Clipbin' : 'Clip'}?`,
          title: 'Update Expiration Days'
      };

      this.dialog.open(ConfirmDialog, {data})
          .afterClosed()
          .subscribe((confirm) => {
            if (confirm && link.editableTtl !== undefined) {
              const ttl = link.editableTtl;

              if (link.type === 'CLIPBIN') {
                this.sharedLinkClipBinService.updateClipBinSharedLinkTtlById(link.documentId, ttl)
                    .then(() => {
                        link.expireTime = this.calculateNewExpireTime(ttl);
                        link.originalTtl = ttl;
                        link.isEditable = false;
                        this.cdr.detectChanges();
                        return;
                    });
              } else {
                this.sharedLinks.updateLinkTtl(link.name, ttl as ExpirationDaysOption)
                  .subscribe(updatedLink => {
                    if (updatedLink) {
                      link.expireTime = this.calculateNewExpireTime(ttl);
                      link.originalTtl = ttl;
                    } else {
                      this.snackbar.error('Failed to change the link expiration time');
                    }
                    link.isEditable = false;
                    this.cdr.detectChanges();
              });
              }
            }
          });
    }

    private calculateNewExpireTime(ttl: number): string {
      if (ttl === 0) {
        return NEVER_EXPIRED_TTL;
      }
      const newExpireTime = this.sharedLinkClipBinService.calculateExpireTime(ttl);
      return this.sharedLinkClipBinService.formatDate(newExpireTime);
    }
}
