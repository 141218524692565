import { animate, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Event as NavigationEvent, Router } from '@angular/router';
import { combineLatest, firstValueFrom, fromEvent, Observable, ReplaySubject } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';

import { assertTruthy, assumeExhaustive } from '../asserts/asserts';
import { FeatureFlagService } from '../feature_flag/feature_flag_service';
import { FirebaseAnalyticsService } from '../firebase/firebase_analytics_service';
import { ResourceContent } from '../landing/clip-bin-section/service/resource.service';
import { SearchFacetGroup } from '../landing/search_facet_group';
import { LiveAssetService } from '../live/live_asset_service';
import { IS_MAINTENANCE } from '../services/auth.guard';
import { BinSectionContent, BinSectionContentType } from '../services/bin.service';
import { ClipbinsOwner } from '../services/bin_api.service';
import { LocalUploadsTrackingService } from '../services/local_uploads_tracking_service';
import { MediaCacheService } from '../services/media_cache_service';
import { ProgressbarService } from '../services/progressbar_service';
import { FacetGroup } from '../services/search_facet_service';
import { SearchInputService, SearchMode, SearchType } from '../services/search_input_service';
import { QUERY_SEPARATOR } from '../services/search_service';
import { HomeView, ShortcutEvents, StateService } from '../services/state_service';
import { ClipbinStorageService } from '../services/storage/clip_bin_storage.service';
import { TransferService } from '../services/transfer_service';
import { VodSearchService } from '../services/vod_search_service';
import { ClipbinFolderCreationDialog } from '../shared/clipbin_folder_creation_dialog/clipbin_folder_creation_dialog';
import { CloudIngestCreationDialog } from '../shared/cloud_ingest_creation_dialog';
import { CreateBinDialog } from '../shared/create_bin_dialog';

import { SearchInput, Suggestion } from './search_input';

const SEARCH_HELP_HEIGHT = 454;
const SLIDE_HELP_TIMING = '200ms ease-out';
const FADE_PROGRESS_BAR_TIMING = '150ms ease-out';

enum ActionType {
    CLIP_BIN = 'Clip Bin',
    LOCAL_UPLOAD = 'Local Upload',
    CLOUD_INGEST = 'Cloud Ingest',
    CLIP_BIN_FOLDER = 'Folder'
}

/**
 * Supported query parameters in the Home page.
 * TODO: Extract navigation into own service.
 */
declare interface MamQueryParams {
    /** String query. */
    query?: string;
    /** Facets selections as a base64. */
    facets?: string;
    liveFacets?: string;
    /** Start and end "Event Date" facet. */
    dateRange?: string;
    /** Segments or full video mode. */
    searchMode?: SearchMode;
    /** Index of details navigation context. */
    index?: string;
    /** Type of details navigation context. */
    type?: string;
    /** Current mediacache site. */
    site?: string;
    /** Live Landing date param */
    llDate?: string;
    /** Live Staging date param */
    lsDate?: string;
}

/**
 * Component for public home page.
 */
@Component({
    selector: 'mam-home',
    templateUrl: './home.ng.html',
    styleUrls: ['./home.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush, // eslint-disable-next-line @angular-eslint/component-max-inline-declarations -- FIXME: Refactor to resuable animations
    animations: [
        trigger('slideHelp', [
            transition(':enter', [
                style({ height: 0 }),
                animate(SLIDE_HELP_TIMING, style({ height: SEARCH_HELP_HEIGHT }))
            ]),
            transition(':leave', [
                style({ height: SEARCH_HELP_HEIGHT }),
                animate(SLIDE_HELP_TIMING, style({ height: 0 }))
            ])
        ]),
        trigger('fadeProgressBar', [
            transition(':enter', [style({ opacity: 0 }), animate(FADE_PROGRESS_BAR_TIMING, style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate(FADE_PROGRESS_BAR_TIMING, style({ opacity: 0 }))])
        ])
    ]
})
export class Home implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(SearchInput) searchInput?: SearchInput;
    @ViewChild('facetScrollView') facetScrollView!: ElementRef<HTMLElement>;
    @ViewChildren('facetGroup') facetGroups?: QueryList<SearchFacetGroup>;

    /** Upload Files Input. */
    @ViewChild('uploadFilesInput', { static: false }) uploadFilesInput!: ElementRef<HTMLInputElement>;

    isSearchHelpDisplayed = false;

    readonly facetQuery$ = this.vodSearchService.facetQuery$;

    readonly liveFacetQuery$ = this.liveAssetService.facetQuery$;

    /** The start date and end date of the date range picker. */
    readonly dateRangeQuery$ = this.vodSearchService.dateRangeQuery$;

    readonly failedTaskCount$ = this.transferService.stats$.pipe(map((stats) => stats?.failedCount));

    readonly facetGroups$ = this.getFacetGroups();

    readonly siteFacetGroup$ = this.liveAssetService.siteFacetGroup$;

    readonly sites$ = this.mediaCache.state.sites$;

    readonly selectableSites$ = this.mediaCache.state.selectableSites$;

    readonly windowResize$: Observable<Event | undefined> = fromEvent(window, 'resize');

    /** Specifies whether or not the scroll right button should be visible. */
    showFacetsScrollRight = false;

    /** Specifies whether or not the scroll left button should be visible. */
    showFacetsScrollLeft = false;

    /** Whether to display the search page instead of the landing page. */
    isSearchPageDisplayed = false;

    readonly currentView$ = this.getCurrentView();

    readonly HomeView = HomeView;

    readonly createActions = [ActionType.CLIP_BIN];

    hasSearchResponse$ = this.vodSearchService.searchResponse$.pipe(map((response) => response != null));

    /** Specifies whether the Clear All button for facets should be visible. */
    isClearAllFacetsVisible = false;

    isStagingView: boolean = false;

    isLiveView: boolean = false;

    showSiteFilter = this.featureFlag.featureOn('live-site-filter');
    // Used to unsubscribe from layout changes.
    private readonly destroyed$ = new ReplaySubject<void>(1);

    private currentSearchMode: BinSectionContentType = BinSectionContent.BIN;
    private resourceContent: ResourceContent | undefined = undefined;

    constructor(
        private readonly analyticsService: FirebaseAnalyticsService,
        private readonly cdr: ChangeDetectorRef,
        private readonly dialog: MatDialog,
        private readonly renderer: Renderer2,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly vodSearchService: VodSearchService,
        private readonly liveAssetService: LiveAssetService,
        private readonly localUploadsTrackingService: LocalUploadsTrackingService,
        readonly progressbar: ProgressbarService,
        readonly searchInputService: SearchInputService,
        readonly stateService: StateService,
        readonly transferService: TransferService,
        readonly mediaCache: MediaCacheService,
        private readonly featureFlag: FeatureFlagService,
        private readonly clipbinStorageService: ClipbinStorageService,
        @Inject(IS_MAINTENANCE) readonly isMaintenance: boolean
    ) {
        this.clipbinStorageService.clear();
        this.clipbinStorageService.init();
    }

    ngOnInit() {
        this.observeUrlParams();

        combineLatest([this.stateService.shortcutEvent$, this.stateService.currentAsset$])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(([event, asset]) => {
                if (asset?.original && event?.intent == ShortcutEvents.SHOW_SOURCE_ASSET) {
                    this.stateService.currentPlayerTime$.next(this.stateService.currentAsset$.value?.startTime);
                    // Navigate to source asset on show source asset shortcut
                    this.router.navigate(['asset', asset.original.name]);
                    this.stateService.shortcutEvent$.next(null);
                }
            });

        // Pass current view to the state service
        this.currentView$.pipe(takeUntil(this.destroyed$)).subscribe((view) => {
            this.stateService.currentView$.next(view);
        });

        this.createActions.push(ActionType.LOCAL_UPLOAD);

        // Add "NEW" actions depending on feature flags.
        if (this.featureFlag.featureOn('use-cloud-ingest')) {
            this.createActions.push(ActionType.CLOUD_INGEST);
        }

        // Show clipbins from all owners if the initial URL is of a clipbin.
        if (this.route.snapshot.firstChild?.params?.['clipbinname']) {
            this.stateService.clipbinsOwner$.next(ClipbinsOwner.ALL);
        }

        if (this.featureFlag.featureOn('enable-clip-bin-organization')) {
            this.createActions.push(ActionType.CLIP_BIN_FOLDER);
        }

        this.stateService.currentView$.pipe(takeUntil(this.destroyed$)).subscribe((view) => {
            this.isStagingView = view === HomeView.STAGING;
            this.isLiveView = view === HomeView.LIVE;
            this.cdr.markForCheck();
        });

        this.stateService.searchModeSelected$.pipe(takeUntil(this.destroyed$)).subscribe((mode) => {
            this.currentSearchMode = mode;
        });

        this.stateService.currentSelectedResource$.pipe(takeUntil(this.destroyed$)).subscribe((resource) => {
            this.resourceContent = resource;
        });
    }

    ngAfterViewInit() {
        // Checks the scroll state when the window size or the facets change
        combineLatest([this.windowResize$.pipe(startWith(null)), this.facetGroups$])
            // Adds a delay for facetScrollView to be available in the DOM.
            .pipe(delay(1), takeUntil(this.destroyed$))
            .subscribe(() => {
                this.checkScrollState();
                this.cdr.markForCheck();
            });

        this.vodSearchService.searchResponse$.pipe(takeUntil(this.destroyed$)).subscribe((response) => {
            // Displays 'Clear All' facets button when a facet is selected
            if (response != null) {
                this.isClearAllFacetsVisible = this.areFacetsSelected();
                this.cdr.detectChanges();
            }
        });
    }

    /**
     * Checks the facet area state, if scrollLeft > 0, show the left arrow button.
     * If scrollWidth - scrollLeft > offsetWidth, it means the facet view is
     * overflow horizontally, shows the right arrow button.
     */
    checkScrollState() {
        if (this.facetScrollView) {
            const scrollLeft = Number(this.facetScrollView.nativeElement.scrollLeft);
            const scrollWidth = Number(this.facetScrollView.nativeElement.scrollWidth);
            const offsetWidth = Number(this.facetScrollView.nativeElement.offsetWidth);
            this.showFacetsScrollLeft = scrollLeft > 0;
            this.showFacetsScrollRight = scrollWidth - scrollLeft > offsetWidth;
            this.cdr.markForCheck();
        }
    }

    /** Handler for when the facet panel is scrolled. */
    onScroll() {
        this.checkScrollState();
    }

    resetFacetScrollPosition() {
        if (this.facetScrollView) {
            this.renderer.setProperty(this.facetScrollView.nativeElement, 'scrollLeft', 0);
        }
    }

    /** Scrolls left by 95% of the facet view */
    scrollLeft() {
        if (this.facetScrollView) {
            const scrollLeft = Number(this.facetScrollView.nativeElement.scrollLeft);
            const offsetWidth = Number(this.facetScrollView.nativeElement.offsetWidth);
            const newScrollLeft = scrollLeft - offsetWidth * 0.95;
            this.renderer.setProperty(this.facetScrollView.nativeElement, 'scrollLeft', newScrollLeft);
        }
    }

    /** Scrolls right by 95% of the facet view */
    scrollRight() {
        if (this.facetScrollView) {
            const scrollLeft = Number(this.facetScrollView.nativeElement.scrollLeft);
            const offsetWidth = Number(this.facetScrollView.nativeElement.offsetWidth);
            const newScrollLeft = scrollLeft + offsetWidth * 0.95;
            this.renderer.setProperty(this.facetScrollView.nativeElement, 'scrollLeft', newScrollLeft);
        }
    }

    observeUrlParams() {
        this.observeQueryParams('query').subscribe((query) => {
            const chips = query
                .split(QUERY_SEPARATOR)
                .map((c) => c.trim())
                .filter((c) => c);
            this.searchInputService.chips$.next(chips);
            // Reset suggestions when the query changes. There is not (input) event
            // emitted when we go back to the landing page or enter a new chip, but
            // the suggestions need to be updated.
            this.updatePartialQuery();
        });

        this.observeQueryParams('facets').subscribe((facets) => {
            this.facetQuery$.next(atob(facets));
        });

        this.observeQueryParams('liveFacets').subscribe((facets) => {
            this.liveFacetQuery$.next(atob(facets));
        });

        this.observeQueryParams('dateRange').subscribe((dateRangeQuery) => {
            // `dateRangeQuery` is a string which is either empty, or contains the
            // startDate and endDate formatted as YYYY-MM-DD separated by a semicolon.
            // Alterations of this format is not supported and will result in errors.
            this.dateRangeQuery$.next(dateRangeQuery);
        });

        this.observeQueryParams('searchMode', SearchMode.VIDEO).subscribe((searchMode) => {
            const currentMode = searchMode === SearchMode.VIDEO ? SearchMode.VIDEO : SearchMode.SEGMENT;
            this.searchInputService.searchMode$.next(currentMode);
        });
    }

    /** Search triggered when the user presses `Enter` from the search input. */
    async search(query: string) {
        // In all cases, we clear the input text.
        this.setSearchInputValue('');

        // If some text was in the input (besides any chips), we convert it into a
        // new chip.
        if (query) {
            const chip = await this.searchInputService.chipify(query);
            this.analyticsService.logSearchEvent('Add search term', { term: chip });
            await this.addChip(chip);
            return;
        }

        // If the input was empty (no text and no chips), we navigate back to
        // VOD or live landing page.
        const chips = await firstValueFrom(this.searchInputService.chips$);
        if (!chips.length) {
            await this.navigateToSearchViewOrLanding();
        }
    }

    ngOnDestroy() {
        // Unsubscribes all pending subscriptions.
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    /**
     * Appends the dateRange into url (if any).
     */
    onDateRangeQueryChange(dateRange?: string) {
        const queryParams: MamQueryParams = { dateRange };
        this.router.navigate([''], {
            queryParams,
            queryParamsHandling: 'merge'
        });
    }

    /**
     * Changes the search mode in the URL, facets should be set to "undefined"
     * whenever the search mode changes.
     */
    changeSearchMode(searchMode: SearchMode) {
        this.analyticsService.logSearchEvent('Search mode changed', { searchMode });
        this.navigateToSearchViewOrLanding({ searchMode });
    }

    /** Fetches new suggestions whenever the search input changes. */
    updatePartialQuery() {
        this.searchInputService.partialQuery$.next(this.searchInput?.value || '');
    }

    backToLanding() {
        this.selectView(HomeView.LANDING);
    }

    clearSearchQuery() {
        // Clear unchipified input.
        this.setSearchInputValue('');
        this.updatePartialQuery();

        // Clear chips/facets and navigate to VOD or live landing page.
        this.navigateToSearchViewOrLanding({
            query: undefined,
            facets: undefined,
            dateRange: undefined
        });
    }

    executeCreateAction(job: ActionType) {
        switch (job) {
            case ActionType.CLIP_BIN:
                this.openCreateBin();
                break;
            case ActionType.LOCAL_UPLOAD:
                this.uploadFilesInput.nativeElement.click();
                break;
            case ActionType.CLOUD_INGEST:
                this.dialog.open(CloudIngestCreationDialog);
                break;
            case ActionType.CLIP_BIN_FOLDER:
                this.dialog.open(ClipbinFolderCreationDialog);
                break;
            default:
                assumeExhaustive(job);
                break;
        }
    }

    openCreateBin() {
        this.dialog.open(CreateBinDialog, {
            ...CreateBinDialog.dialogOptions,
            data: { parent: this.resourceContent?.parent }
        });
    }

    async addChip(chip: string) {
        const previousChips = await firstValueFrom(this.searchInputService.chips$);
        const newChips = [...previousChips, chip];
        this.updateSearchUrl(newChips);
    }

    async removeChip(chipIndex: number) {
        const previousChips = await firstValueFrom(this.searchInputService.chips$);
        const term = previousChips[chipIndex];
        const newChips = [...previousChips];
        newChips.splice(chipIndex, 1);
        this.analyticsService.logSearchEvent('Remove search term', { term });
        this.updateSearchUrl(newChips);
    }

    async editChip({ index, content }: { index: number; content: string }) {
        const previousChips = await firstValueFrom(this.searchInputService.chips$);
        const newChips = [...previousChips];
        const previousTerm = newChips[index];
        const newChip = await this.searchInputService.chipify(content);
        newChips[index] = newChip;
        this.analyticsService.logSearchEvent('Edited search term', { term: `${previousTerm} -> ${newChip}` });
        this.updateSearchUrl(newChips);
    }

    removeFromHistory(suggestion: Suggestion) {
        this.searchInputService.removeFromHistory(suggestion);
        // Focusing the input will allow to navigate the suggestion list with
        // up/down keys or press Tab to autocomplete.
        this.searchInput?.focus(false);
    }

    suggestionSelected(suggestion: Suggestion) {
        // TODO: Consider moving search input related logic to
        // SearchInputService
        const fromHistoryText = suggestion.isFromHistory ? 'from history' : '';
        if (suggestion.searchOperatorSuggestion) {
            this.setSearchInputValue(suggestion.text + ' ');
            this.analyticsService.logSearchEvent(`Use suggested operator ${fromHistoryText}`, {
                term: suggestion.text
            });
        } else {
            this.setSearchInputValue('');
            this.analyticsService.logSearchEvent(`Use suggested term ${fromHistoryText}`, {
                term: suggestion.text
            });
            this.addChip(suggestion.text);
        }
        this.updatePartialQuery();
    }

    onMenuClose(facetQuery: string) {
        this.navigateToSearchViewOrLanding({ facets: this.encodeFacetQuery(facetQuery) });
    }

    onLiveMenuClose(facetQuery: string) {
        this.navigateToSearchViewOrLanding({ liveFacets: this.encodeFacetQuery(facetQuery) });
    }

    clearAllFacetsAndNavigate() {
        this.navigateToSearchViewOrLanding({ facets: undefined, dateRange: undefined });
        this.clearAllFacets();
    }

    clearAllFacets() {
        this.isClearAllFacetsVisible = false;
        this.resetFacetScrollPosition();
    }

    // Returns true if a facet is selected or a date range is specified
    areFacetsSelected(): boolean {
        return !!this.facetQuery$.getValue() || !!this.dateRangeQuery$.getValue();
    }

    selectView(view: HomeView) {
        // Clear the search input from any temporary text (not converted to a chip
        // yet) when a new view is selected.
        this.setSearchInputValue('');

        // Remove any search or details context from the query parameters.
        const queryParams: MamQueryParams = {
            query: undefined,
            facets: undefined,
            liveFacets: undefined,
            dateRange: undefined,
            index: undefined,
            type: undefined,
            llDate: undefined,
            lsDate: undefined
        };

        if (view !== HomeView.SEARCH_RESULTS) {
            this.clearAllFacets();
        }

        switch (view) {
            case HomeView.LIVE:
                return this.router.navigate(['live'], { queryParams, queryParamsHandling: 'merge' });
            case HomeView.JOBS:
                return this.router.navigate(['jobs'], { queryParams, queryParamsHandling: 'merge' });
            case HomeView.STAGING:
                return this.router.navigate(['staging'], { queryParams, queryParamsHandling: 'merge' });
            case HomeView.LANDING:
                return this.router.navigate([''], { queryParams, queryParamsHandling: 'merge' });
            case HomeView.ADMIN:
                return this.router.navigate(['admin'], { queryParams, queryParamsHandling: 'merge' });
            default:
                throw new Error(`${view} manual selection is not supported`);
        }
    }

    uploadFiles() {
        const selectedFiles = this.uploadFilesInput.nativeElement.files;
        assertTruthy(selectedFiles?.length, 'Home.uploadFiles expected non-empty files');
        this.localUploadsTrackingService.uploadFiles(Array.from(selectedFiles));
        // no matter it succeeds or not, need to reset the input value.
        this.uploadFilesInput.nativeElement.value = '';
    }

    onScrollPage(evt: Event) {
        this.stateService.scrollHappened$.next(evt);
    }

    private encodeFacetQuery(facetQuery: string) {
        return facetQuery ? btoa(facetQuery) : undefined;
    }

    private getFacetGroups(): Observable<FacetGroup[] | undefined> {
        return this.vodSearchService.searchResponse$.pipe(map((response) => response?.facetGroups));
    }

    private observeQueryParams<T extends keyof MamQueryParams>(param: T, defaultValue = ''): Observable<string> {
        return this.route.queryParamMap.pipe(
            map((params) => String(params.get(param) || defaultValue)),
            distinctUntilChanged()
        );
    }

    private updateSearchUrl(chips: string[]) {
        this.navigateToSearchViewOrLanding({ query: chips.length ? chips.join(QUERY_SEPARATOR) : undefined });
    }

    private getCurrentView(): Observable<HomeView> {
        return this.router.events
            .pipe(
                startWith(
                    // Takes the url of the window to set this.currentView so
                    // that the sidenav is correctly formatted
                    new NavigationEnd(0, window.location.pathname, window.location.pathname)
                ),
                filter((e: NavigationEvent) => e instanceof NavigationEnd),
                withLatestFrom(this.searchInputService.chips$)
            )
            .pipe(
                map(([event, chips]) => {
                    // If executed by a url change, change the view page
                    if (event instanceof NavigationEnd) {
                        // This takes first part of url path
                        // '/transfers?test' => 'transfers'
                        // '/?test' => ''
                        // '/transfers/vod?test' => 'transfers'
                        const type: string = event.url.match(/\/([^/^?]+)/)?.[1] ?? '';

                        if (Object.values(HomeView).includes(type as HomeView)) {
                            return type as HomeView;
                        }

                        if (type === 'asset' || type === 'clipbin') {
                            return HomeView.DETAILS;
                        }
                    }

                    // If the search query is present OR a facet is selected OR a date is
                    // specified, show search results
                    if (chips.length || this.areFacetsSelected()) {
                        return HomeView.SEARCH_RESULTS;
                    }

                    // In any other case, default view is LANDING.
                    return HomeView.LANDING;
                })
            );
    }

    /**
     * Navigates to search results or landing page, based on search context.
     *
     * Any changes to search context (changing search query, facet selection,
     * search mode, etc) results in user being redirected to live or VOD search
     * results depending on current searchType.
     *
     * Redirects to landing if the searchType is VOD and the search query is
     * empty.
     */
    private async navigateToSearchViewOrLanding(
        queryParams: { [key in keyof MamQueryParams]: string | undefined } = {}
    ) {
        const searchType = await firstValueFrom(this.searchInputService.searchType$);
        const searchRoute = searchType === SearchType.VOD ? '' : 'live';
        return this.router.navigate([searchRoute], {
            // Always clear type and index params as they are only relevant within
            // particular search context.
            queryParams: { ...queryParams, type: undefined, index: undefined },
            queryParamsHandling: 'merge'
        });
    }

    private setSearchInputValue(value: string) {
        if (this.searchInput) {
            this.searchInput.value = value;
        }
    }
}
