import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject, takeUntil } from 'rxjs';

import { assertTruthy } from '../../asserts/asserts';
import { ErrorResponse } from '../../error_service/error_response';
import { FeatureFlagService } from '../../feature_flag/feature_flag_service';
import { AssetService, Clip, ListResponse } from '../../services/asset_service';
import { Bin, BinWithClips } from '../../services/bin.service';
import { DisplayMode } from '../../services/vod_search_service';
import { ClipBinBinMoveDialog } from '../../shared/clipbin_bin_move_dialog/clipbin_bin_move_dialog';
import { DeleteBinDialog, DeleteBinDialogData } from '../../shared/delete_bin_dialog';
import { GetLinkForBinDialog } from '../../shared/get_link_for_bin_dialog';
import { RenameBinDialog } from '../../shared/rename_bin_dialog';
import { Resource } from '../clip-bin-section/service/resource.service';
import { ContentEvent } from '../folder-content/folder-content.component';

export interface ResourceBin extends Resource, BinWithClips {}

/**
 * Clickable clip bin card with up to 3 clips previews.
 */
@Component({
    selector: 'mam-clip-bin-preview',
    templateUrl: './clip-bin-preview.ng.html',
    styleUrls: ['./clip-bin-preview.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClipBinPreview implements OnInit, OnDestroy {
    @Input() bin!: BinWithClips | Bin | Resource | ResourceBin;

    // toggles between grid and list view
    @Input() binsViewMode: DisplayMode = DisplayMode.GRID;

    //toggles between active and inactive in a selection
    @Input() active: boolean = false;

    @Input() isAdmin: boolean = false;
    @Input() showAllBins: boolean | undefined = undefined;
    @Input() isMyFolder: boolean | undefined = undefined;

    @Output() contentEvent = new EventEmitter<ContentEvent>();

    @Input() sharingAdditionalProperties?: Record<string, string>;

    @Input() checkboxPresent: boolean = false;
    clips: Clip[] = [];
    /** True when layout switches to SMALL. */
    isSmallScreen = false;
    /** True when layout switches to MID. */
    isMidScreen = false;
    /** True when layout switches to Large. */
    isLargeScreen = false;
    /** True when layout switches to SMALL Landscape mode. */
    isSmallScreenLandScape = false;
    private readonly destroyed$ = new ReplaySubject<void>(1);
    isLocationExpanded:boolean = false;

    constructor(
        private readonly dialog: MatDialog,
        private readonly cdr: ChangeDetectorRef,
        private readonly assetService: AssetService,
        readonly featureService: FeatureFlagService
    ) {}

    isResourceBin(bin: BinWithClips | Bin | Resource | ResourceBin | undefined): bin is ResourceBin {
        return bin != null && typeof bin === 'object' && 'iasData' in bin && 'clips' in bin;
    }

    isResource(bin: BinWithClips | Bin | Resource | ResourceBin | undefined): bin is Resource {
        return bin != null && typeof bin === 'object' && 'iasData' in bin;
    }

    get binValue(): BinWithClips | Bin | Resource | ResourceBin {
        return this.bin;
    }

    get binWithClips(): BinWithClips {
        const bin = this.bin as BinWithClips;

        if (this.clips.length > 0) {
            bin.clips = this.clips;
        }

        return bin;
    }

    ngOnInit() {
        // this.getClips();
        assertTruthy(this.bin, '<mam-clip-bin-preview> requires property "bin"');
    }

    getClips() {
        if (this.bin && 'name' in this.bin) {
            this.assetService.searchClips(this.bin.name, undefined, undefined, 5).subscribe((clipResult) => {
                if (clipResult instanceof ErrorResponse) {
                    return;
                }

                this.clips = (clipResult as ListResponse<Clip>).assets;
                this.cdr.markForCheck();
            });
        }
    }

    trackClip(index: number, clip: Clip) {
        return clip.name;
    }

    openRenameBin(title: string, name: string) {
        //If it's a BFF resource, change name.
        if (this.isResource(this.bin)) {
            const res = this.bin;
            name = res.iasData.label.name;
        }
        this.dialog
            .open(RenameBinDialog, {
                ...RenameBinDialog.dialogOptions,
                data: { title, name }
            })
            .afterClosed()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((newTitle) => {
                if (!newTitle) {
                    return;
                }
                if ('title' in this.bin) {
                    this.bin.title = newTitle;
                }
                const binEventResult = { ...this.bin, retiitled: newTitle };
                this.contentEvent.emit(binEventResult as unknown as ContentEvent);
                this.cdr.markForCheck();
            });
    }

    openDeleteBin(binValue: BinWithClips | Bin | Resource | ResourceBin) {
        let newVar: Resource;
        if (this.isResource(binValue)) {
            newVar = binValue;
        } else if ('displayName' in binValue && 'name' in binValue) {
            newVar = this.getResource(binValue.name);
        } else {
            throw new Error('Invalid binValue type');
        }
        this.dialog
            .open<DeleteBinDialog, DeleteBinDialogData>(DeleteBinDialog, {
                ...DeleteBinDialog.dialogOptions,
                data: {
                    resource: newVar
                }
            })
            .afterClosed()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((deletionConfirmed) => {
                const binEventResult = { ...this.bin, deleted: deletionConfirmed };
                this.contentEvent.emit(binEventResult as unknown as ContentEvent);
            });
    }

    openMoveFolder() {
        this.dialog.open(ClipBinBinMoveDialog, {
            ...ClipBinBinMoveDialog.dialogOptions,
            data: this.bin
        });
    }

    openShareBin() {
        this.dialog.open(
            GetLinkForBinDialog,
            GetLinkForBinDialog.getDialogOptions({
                bin: this.bin as BinWithClips,
                additionalProperties: this.sharingAdditionalProperties
            })
        );
    }

    ngOnDestroy() {
        // Unsubscribes all pending subscriptions.
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    getTitle(binValue: BinWithClips | Bin | Resource | ResourceBin) {
        if (this.isResource(binValue)) {
            return binValue.name;
        } else if ('title' in binValue) {
            return binValue.title;
        } else {
            throw new Error('Invalid binValue type');
        }
    }

    getAssetCount(binValue: BinWithClips | Bin | Resource | ResourceBin): number {
        if(this.isLocationExpanded) return -1;
        if (this.isResource(binValue)) {
            return binValue.iasData.assetCount || 0;
        } else if ('assetCount' in binValue) {
            return Number.parseInt(binValue.assetCount, 10) || 0;
        } else {
            throw new Error('Invalid binValue type');
        }
    }

    getClipBinId(binValue: BinWithClips | Bin | Resource | ResourceBin) {
        if (this.isResource(binValue)) {
            return binValue.iasData.label.name;
        } else if ('name' in binValue) {
            return binValue.name;
        } else {
            throw new Error('Invalid binValue type');
        }
    }

    /**
     * Returns the `bin` input as a `ResourceBin` if possible.
     * If `bin` is already a `ResourceBin`, it is returned directly.
     * If `bin` is a `BinWithClips`, a new `ResourceBin` is created with default `Resource` properties.
     * Otherwise, `undefined` is returned.
     */
    getBinAsResourceBin(): ResourceBin | Resource | undefined {
        if (this.isResourceBin(this.bin)) {
            return this.bin;
        } else if (this.isResource(this.bin)) {
            return this.bin;
        } else if (typeof this.bin === 'object' && 'clips' in this.bin) {
            const binWithClips = this.bin as BinWithClips;
            return {
                id: binWithClips.name ?? '',
                displayName: binWithClips.title ?? '',
                name: binWithClips.name ?? '',
                createdAt: binWithClips.createTime ? new Date(binWithClips.createTime).toISOString() : '',
                level: 0,
                subTreeDepth: 0,
                directChildrenCount: 0,
                title: binWithClips.title ?? '',
                createTime: binWithClips.createTime,
                assetCount: binWithClips.assetCount,
                clips: binWithClips.clips,
                compReelInfo: binWithClips.compReelInfo
            };
        } else {
            return undefined;
        }
    }

    private getResource(id: string): Resource {
        return {
            createdAt: '',
            displayName: '',
            level: 0,
            name: '',
            subTreeDepth: 0,
            iasId: id,
            iasData: {
                label: {
                    name: id
                }
            }
        };
    }

    onIsExpandedChange(isExpanded: boolean) {
       this.isLocationExpanded = isExpanded;
    }

}
