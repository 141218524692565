import { BreakpointObserver } from '@angular/cdk/layout';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router, UrlSegment, UrlTree } from '@angular/router';
import {
    BehaviorSubject,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    EMPTY,
    map,
    merge,
    Observable,
    ReplaySubject,
    startWith,
    Subject,
    switchMap,
    take,
    takeUntil
} from 'rxjs';

import { AuthService } from '../../auth/auth_service';
import { FeatureFlagService } from '../../feature_flag/feature_flag_service';
import { ClipBinsFoldersService } from '../../firebase/clip_bins_folders.service';
import { FirebaseAnalyticsService } from '../../firebase/firebase_analytics_service';
import { Asset, Clip } from '../../services/asset_service';
import { Bin, BinSectionContent, BinSectionContentType, BinService, BinWithClips } from '../../services/bin.service';
import { ClipbinsOwner } from '../../services/bin_api.service';
import { ClipApiService } from '../../services/clip_api_service';
import { MediaCacheService } from '../../services/media_cache_service';
import { Pagination, PaginationService } from '../../services/pagination_service';
import { PaginatorIntl, UNKNOWN_LENGTH } from '../../services/paginator-intl';
import { SearchType } from '../../services/search_api.service';
import { SearchInputService } from '../../services/search_input_service';
import { SnackBarService } from '../../services/snackbar_service';
import { StateService } from '../../services/state_service';
import { DisplayMode } from '../../services/vod_search_service';
import { DeleteMultipleBinDialog } from '../../shared/delete_multiple_bin_dialog/delete_multiple_bin_dialog';
import { ScrubbingService } from '../../shared/scrubbing_service';
import { BINS_PAGINATION_BREAKPOINTS, Breakpoint, DisplayModePagination } from '../landing-helpers.utils';

import { ResourceType, ResourceTypes } from './service/resource-types';
import { PaginationInfo, Resource, ResourceService, SearchOptions } from './service/resource.service';

const DEFAULT_BINS_PAGE_SIZE = 12;
const SEARCH_DEBOUNCE = 500;

export interface FoldersApiResult {
    folders: Resource[];
    paginationData: PaginationApiResult;
}

export interface PaginationApiResult {
    pageSize: number;
    totalItems: number;
    totalPages: number;
}

type pageResult = {
    assets?: Asset[];
    bins?: Bin[];
    nextPageToken?: string;
};

/**
 * Component for displaying and managing clip bins and folders.
 * Provides search, filtering, pagination, and display mode options.
 */
@Component({
    selector: 'mam-clip-bin-section',
    templateUrl: './clip-bin-section.component.html',
    styleUrl: './clip-bin-section.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: MatPaginatorIntl, useClass: PaginatorIntl }]
})
export class ClipBinSection implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;
    @ViewChild('searchField') searchField?: MatFormField;
    @ViewChild('scrollableView') scrollableView!: ElementRef<HTMLElement>;

    /* Pagination */
    /** Pagination object for bins. */
    binsPagination: Pagination<pageResult>;
    /** Pagination object for clips. */
    clipsPagination: Pagination<pageResult>;
    /** Pagination object for folders. */
    foldersPagination = {
        pageLimit: 6,
        nextCursor: null as Resource | null,
        lastCursor: null as Resource | null,
        pageIndex: 0,
        totalCount: 0
    };

    pageIndex$ = new BehaviorSubject(0);

    /* Loading */
    /** Indicates if results are loading. */
    resultsLoading = true;
    /** Observable for loading state based on screen breakpoint. */
    resultsLoading$: Observable<number[]> = this.breakpointObserver.observe(['(max-width: 1300px)']).pipe(
        take(1),
        map((result) =>
            result.matches ? Array.from({ length: this.retrievePaginationLimit() }) : Array.from({ length: 5 })
        )
    );

    /* Controls lock */
    controlsLocked$ = new BehaviorSubject<boolean>(false);
    searchControl = new FormControl<string | null>('');
    assetOwner$ = new ReplaySubject<ClipbinsOwner>(1);
    resources$ = new BehaviorSubject<Bin[] | Clip[] | Resource[]>([]);
    /** searchMode array of available choices. */
    searchModeOptions = this.featureService.featureOn('enable-clip-search')
        ? [BinSectionContent.FOLDER, BinSectionContent.BIN, BinSectionContent.CLIP]
        : [BinSectionContent.FOLDER, BinSectionContent.BIN];
    searchModeSelected: BinSectionContentType = BinSectionContent.FOLDER;
    searchModeSelected$: BehaviorSubject<BinSectionContentType> = new BehaviorSubject<BinSectionContentType>(
        BinSectionContent.FOLDER
    );
    searchModeVisible: BinSectionContentType = BinSectionContent.FOLDER;
    searchText: string | null = null;
    displayMode = DisplayMode.GRID;
    SEARCH_MODE = BinSectionContent;
    searchModeDisabled = false;
    // Display booleans
    /** Show all assets (including those from other users). */
    showAllAssets: boolean = false;
    /** Indicates if the "Show All Assets" toggle is disabled. */
    isShowAllAssetsDisabled: boolean = false;
    /** Indicates if the paginator is disabled. */
    isPaginatorDisabled: boolean = false;
    // Pagination
    private startAfter?: string;

    /** Observable for the current pagination breakpoints. */
    currentPagination$: BehaviorSubject<DisplayModePagination[]> = new BehaviorSubject<DisplayModePagination[]>(
        BINS_PAGINATION_BREAKPOINTS.get(Breakpoint.LARGE) || []
    );
    folderUrlId: string | null = null;
    /** Account data */
    username: string = '';
    // persist clipbin multiselection
    itemMultiSelection = new Set<string>();
    lastSelectedIndex: number = 0;
    protected isAdmin: boolean = false;
    protected userEmail: string = '';
    /* SearchResults */
    private searchChanged$: Observable<string | null>;
    private searchSubject = new Subject<string>();
    private readonly destroyed$ = new ReplaySubject<void>(1);

    constructor(
        private readonly binService: BinService,
        private readonly clipService: ClipApiService,
        private readonly cdr: ChangeDetectorRef,
        private readonly snackBar: SnackBarService,
        readonly searchInputService: SearchInputService,
        private analyticsService: FirebaseAnalyticsService,
        private authService: AuthService,
        readonly stateService: StateService,
        readonly mediaCache: MediaCacheService,
        readonly scrubbingService: ScrubbingService,
        private readonly paginationService: PaginationService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly clipBinFolderService: ClipBinsFoldersService,
        private readonly dialog: MatDialog,
        private readonly route: ActivatedRoute,
        private readonly featureService: FeatureFlagService,
        private readonly resourceService: ResourceService,
        private readonly router: Router
    ) {
        /**
         * Initialize component variables and set up subscriptions.
         */
        this.searchInputService.searchType$.next(SearchType.VOD);
        this.searchChanged$ = this.getSearchChanged();
        this.assetOwner$.next(stateService.clipbinsOwner$.value);
        this.binsPagination = this.paginationService.getEmptyPagination(DEFAULT_BINS_PAGE_SIZE);
        this.clipsPagination = this.paginationService.getEmptyPagination(DEFAULT_BINS_PAGE_SIZE);
        // this.foldersPagination = this.paginationService.getEmptyPagination(DEFAULT_BINS_PAGE_SIZE);
        this.binService.displayMode$.next(DisplayMode.GRID);

        this.currentPagination$.next(BINS_PAGINATION_BREAKPOINTS.get(Breakpoint.LARGE) || []);

        this.binService.displayMode$.subscribe((mode) => {
            this.displayMode = mode ?? DisplayMode.GRID;
        });

        // Define and listen for resize to the screen breakpoint
        // this.listenForScreenBreakpoint();

        // Resets pagination and scrolls top when search value changes or clip bins get updated
        this.listenForSearchAndPageChanges();
        // Get user's name
        const accountUserName = this.authService.getUserName();
        this.username = accountUserName.split(' ')[0] || 'User';

        this.isAdmin = this.authService.isAdmin;
        this.userEmail = this.authService.getUserEmail();
    }

    get binResults() {
        return this.resources$.value as Bin[];
    }

    get clipResults() {
        return this.resources$.value as Clip[];
    }

    get folderResults() {
        return this.resources$.value as Resource[];
    }

    get allChecked() {
        return this.resources$.value.length > 0 && this.itemMultiSelection.size === this.resources$.value.length;
    }

    get someChecked() {
        return (
            this.resources$.value.length > 0 &&
            this.itemMultiSelection.size > 0 &&
            this.itemMultiSelection.size < this.resources$.value.length
        );
    }

    ngOnInit() {
        this.updateResults();
        this.listenForNavigationChanges();
        this.resourceService.currentResources$.pipe(takeUntil(this.destroyed$)).subscribe((resources) => {
            this.resources$.next(resources as unknown as Resource[]);
        });
        this.resourceService.paginationInfo$.pipe(takeUntil(this.destroyed$)).subscribe((pagination) => {
            if (pagination.paginationResult) {
                if (this.searchModeSelected === 'folders') {
                    this.foldersPagination.pageLimit = pagination.paginationResult.pageSize;
                    this.foldersPagination.totalCount = pagination.paginationResult.totalItems;
                    this.foldersPagination.pageIndex = pagination.offset;
                } else {
                    this.binsPagination.pageSize = DEFAULT_BINS_PAGE_SIZE;
                    this.binsPagination.pageIndex = pagination.offset;
                    this.binsPagination.totalCount = UNKNOWN_LENGTH;

                    if (pagination.paginationResult.startAfter)
                        this.binsPagination.nextPageToken = pagination.paginationResult.startAfter;
                }
            }
        });
        this.controlsLocked$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
            this.isShowAllAssetsDisabled = value;
            this.isPaginatorDisabled = value;
        });
        this.stateService.searchModeSelected$.pipe(takeUntil(this.destroyed$)).subscribe((val) => {
            this.searchModeSelected$.next(val);
        });
    }

    /**
     * Fetches paginated resources (folders, bins, or clips) based on the
     * provided parameters.
     *
     * @param resourceType The type of resource to fetch.
     * @param owner The owner of the resources.
     * @param searchTerm The search term to filter resources.
     * @param pageIndex The page index to fetch.
     * @param pageSize The number of items per page.
     * @param startAfter The ID of the item to start after (for pagination).
     */
    getPaginatedResources(
        resourceType: ResourceType,
        owner: string,
        searchTerm: string,
        pageIndex?: number,
        pageSize?: number,
        startAfter?: string
    ) {
        this.controlsLocked$.next(true);
        this.resultsLoading = true;
        const pSize = pageSize || DEFAULT_BINS_PAGE_SIZE;
        const pIndex = pageIndex || 0;

        const paginatedValue: PaginationInfo = {
            limit: pSize,
            offset: pIndex
        };

        if (startAfter) paginatedValue.startAfter = startAfter;

        const searchOptions: SearchOptions = {
            searchTerm: searchTerm,
            owner: owner === 'current_user' ? this.authService.getUserEmail() : '',
            type: resourceType.name
        };

        this.stateService.clipbinsOwner$.next(owner === 'current_user' ? ClipbinsOwner.USER : ClipbinsOwner.ALL);
        const { isNestedFolder, parentId } = this.extractUrlDetails();
        if (!isNestedFolder && searchOptions.owner !== '' && !searchTerm) searchOptions['level'] = 0;

        if (isNestedFolder && parentId) {
            this.resourceService
                .getResourceChildren(ResourceTypes.FOLDER, parentId, paginatedValue)
                .pipe(takeUntil(this.destroyed$))
                .subscribe({
                    complete: () => {
                        this.resultsLoading = false;
                        this.controlsLocked$.next(false);
                    }
                });
        } else {
            this.resourceService
                .getResource(resourceType, paginatedValue, searchOptions)
                .pipe(takeUntil(this.destroyed$))
                .subscribe({
                    complete: () => {
                        const pagination = this.resourceService.paginationInfo$.value;

                        //Hack for clip-bin navigation disabling the next button navigator when we don't have token for next page. It means that the pagination has come to an end.
                        if (resourceType.name === 'clip-bin' && pagination.offset > 0) {
                            const nextButtonNav = document.querySelector('.mat-mdc-paginator-navigation-next');
                            if (nextButtonNav) {
                                window.setTimeout(() => {
                                    if (pagination.paginationResult?.startAfter) {
                                        nextButtonNav.removeAttribute('disabled');
                                    } else {
                                        nextButtonNav.setAttribute('disabled', 'disabled');
                                    }
                                }, 100);
                            }
                        }

                        this.resultsLoading = false;
                        this.controlsLocked$.next(false);
                    }
                });
        }
    }

    ngAfterViewInit(): void {
        this.registerKeydownListeners();
    }

    listenForNavigationChanges() {
        combineLatest([this.route.url, this.route.queryParams])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(([url]) => {
                this.resultsLoading = true;
                if (url[0]?.path === 'folders') {
                    this.folderUrlId = url[1]?.path;
                    this.searchModeSelected = BinSectionContent.FOLDER;
                    this.resultsLoading = false;
                }
                this.setIsShowAllDisabled();
            });
    }

    listenForSearchAndPageChanges() {
        this.resources$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.resultsLoading = false;
        });
        merge(this.searchChanged$, this.assetOwner$)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.resetPagination();
                this.cdr.markForCheck();
            });

        // Update current page index onPageChange events
        this.pageIndex$.pipe(takeUntil(this.destroyed$)).subscribe((pageIndex) => {
            switch (this.searchModeVisible) {
                case BinSectionContent.CLIP:
                    this.clipsPagination.pageIndex = pageIndex;
                    this.binsPagination.pageIndex = 0;
                    this.foldersPagination.pageIndex = 0;
                    break;
                case BinSectionContent.BIN:
                    this.binsPagination.pageIndex = pageIndex;
                    this.clipsPagination.pageIndex = 0;
                    this.foldersPagination.pageIndex = 0;
                    break;
                case BinSectionContent.FOLDER:
                    this.foldersPagination.lastCursor = { ...this.foldersPagination.nextCursor } as Resource;
                    this.foldersPagination.nextCursor = this.folderResults[this.folderResults.length - 1];
                    this.foldersPagination.pageIndex = pageIndex;
                    this.clipsPagination.pageIndex = 0;
                    this.binsPagination.pageIndex = 0;
                    break;
            }
        });
    }

    /**
     * Search clips
     */
    searchClips(searchTerm: string | null) {
        if (!searchTerm) return EMPTY;

        return this.clipService.searchClipsByTitle(
            searchTerm /* query: string */,
            this.retrievePaginationLimit() /* pageSize:number*/,
            this.clipsPagination.nextPageToken /* pageToken:string */
        );
    }

    /**
     * Loads the initial bins list for the current owner and updates the results based on changes.
     */
    updateResults() {
        combineLatest([
            this.searchChanged$,
            this.assetOwner$,
            this.pageIndex$,
            this.searchModeSelected$,
            this.binService.binsUpdated$,
            this.currentPagination$,
            this.binService.displayMode$
        ])
            .pipe(
                debounceTime(SEARCH_DEBOUNCE), //TODO: fix this!
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                switchMap(([title, owner, pageIndex, searchModeSelected, binsUpdated]) => {
                    const result = new Observable<pageResult | Resource[] | null>();

                    this.resultsLoading = true;
                    this.searchModeSelected = searchModeSelected;
                    this.searchModeVisible = searchModeSelected;

                    const searchTerm = this.searchText as string;

                    const searchOptions = this.resourceService.searchOptions$.value;
                    this.showAllAssets = owner !== 'current_user';

                    this.getPaginatedResources(
                      searchModeSelected === BinSectionContent.FOLDER ? ResourceTypes.FOLDER : ResourceTypes.CLIPBIN,
                      owner,
                      searchTerm,
                      searchTerm !== searchOptions.searchTerm ? 0 : pageIndex,
                      DEFAULT_BINS_PAGE_SIZE,
                      this.startAfter
                    );

                    this.cdr.markForCheck();

                    return result;
                }),
                takeUntil(this.destroyed$)
            )
            .subscribe((res) => {
                if (!res) {
                    this.snackBar.error(`${this.formatBinSectionContent(this.searchModeSelected)} could not be loaded`);
                    this.resources$.next([]);
                } else {
                    this.processSearchResults(res);
                }
                this.cdr.detectChanges();
            });
    }

    /**
     * Retrieves the pagination limit based on the breakpoint pagination options
     * and active display mode.
     */
    retrievePaginationLimit(): number {
        const currentBreakpoint = this.currentPagination$.getValue();
        const activeDisplayMode = currentBreakpoint.find((el) => el.displayMode === this.displayMode);
        if (!activeDisplayMode) return DEFAULT_BINS_PAGE_SIZE;
        return activeDisplayMode?.limit || 0;
    }

    getPlaceholderText() {
        if (!this.searchModeSelected) return '';
        switch (this.searchModeSelected) {
            case BinSectionContent.FOLDER:
                return 'Search folders';
            case BinSectionContent.BIN:
                return 'Search clip bins';
            case BinSectionContent.CLIP:
                return 'Search clips';
        }
    }

    formatBinSectionContent(mode: BinSectionContentType) {
        switch (mode) {
            case BinSectionContent.FOLDER:
                return 'Folders';
            case BinSectionContent.BIN:
                return 'Clip bins';
            case BinSectionContent.CLIP:
                return 'Clips';
        }
    }

    handleSearchModeChange(mode: BinSectionContentType) {
        this.stateService.searchModeSelected$.next(mode);
        const { isNestedFolder } = this.extractUrlDetails();
        if (isNestedFolder && mode === BinSectionContent.BIN) {
            this.router.navigate(['/']);
        }
        if (this.searchModeSelected !== mode) {
            this.pageIndex$.next(0);
        }
        this.searchModeSelected = mode;
        this.searchModeSelected$.next(mode);
        if (mode !== BinSectionContent.BIN && this.showAllAssets) {
            this.toggleShowAllAssets(false);
        }

        this.setIsShowAllDisabled();
        this.itemMultiSelection.clear();
    }

    shouldShowCheckbox() {
        return false;
        //This was removed for demo purposes.
        //return this.searchModeVisible === this.SEARCH_MODE.BIN && !this.showAllAssets
    }

    setIsShowAllDisabled() {
        this.isShowAllAssetsDisabled = !!this.folderUrlId || this.searchModeSelected === BinSectionContent.CLIP;
    }

    /** Toggle the visualization of the user's clip bins and all clip bins */
    onShowAllChange(event: MatSlideToggleChange) {
        this.toggleShowAllAssets(event.checked);
        this.itemMultiSelection.clear();
    }

    /* Handles the toggling of clip bins visualization with or without direct 'show all' event */
    toggleShowAllAssets(toggle: boolean | null = null): void {
        toggle == null ? (this.showAllAssets = !this.showAllAssets) : (this.showAllAssets = !!toggle);

        this.assetOwner$.next(this.showAllAssets ? ClipbinsOwner.ALL : ClipbinsOwner.USER);
    }

    /** Toggles between grid and list view */
    toggleViewMode() {
        const nextMode = this.isGrid(this.displayMode) ? DisplayMode.LIST : DisplayMode.GRID;
        this.binService.displayMode$.next(nextMode);
    }

    isGrid(displayMode: DisplayMode) {
        return displayMode === DisplayMode.GRID;
    }

    onSearchClear() {
        this.resultsLoading = true;
        this.searchControl.setValue('');
        this.searchText = null;
        this.updateResults();
    }

    onPageChange(event: PageEvent) {
        this.resultsLoading = true;
        this.cdr.markForCheck();

        const urlTree = this.router.parseUrl(this.router.url);
        const isNestedFolder = urlTree.root.children['primary']?.segments[0]?.path === 'folders';

        const res = isNestedFolder
            ? this.resourceService.currentContext$.value.parent.children
            : this.resourceService.currentResources$.value;

        if (event.pageIndex !== 0) {
            if (event.previousPageIndex != null && event.pageIndex > event.previousPageIndex) {
              this.startAfter =
                this.searchModeSelected === 'folders' ?
                res[res.length - 1].id as string :
                this.resourceService.paginationInfo$.value.paginationResult?.startAfter || '';
            } else {
                const lastResource =
                  this.searchModeSelected === 'folders' ?
                  this.resourceService.getLastResourceByPage(event.pageIndex - 1) :
                  this.resourceService.getLastBinResourceByPage(event.pageIndex - 1);
                if (lastResource) this.startAfter = lastResource.resourceId as string;
            }
        } else {
          this.startAfter = undefined;
        }

        this.pageIndex$.next(event.pageIndex);

        this.itemMultiSelection.clear();
    }

    trackBy(index: number, value: Resource | BinWithClips | Asset | Clip) {
        return (value as BinWithClips | Asset | Clip)?.name ?? (value as Resource)?.id;
    }

    /** Tag words that match the prefix so they can be displayed bold. */
    getWordsStyles(text: string): Array<{ matches: boolean; value: string }> {
        const prefixes = this.searchControl.value?.trim()?.toLowerCase().split(' ') || [];
        return text?.split(' ').map((value) => {
            const matches = prefixes?.some((prefix: string) => prefix && value?.toLowerCase().startsWith(prefix));
            return { matches, value };
        });
    }

    /**
     * Toggles manual selection of a clip bin for bulk operation. If the "shift" key
     * is pressed, all clips between the last selected one and the current clicked
     * one will be toggled.
     */
    toggleSelection(event: MouseEvent, bin: Bin) {
        const currentIndex = (this.resources$.value as Bin[]).findIndex((b) => b.name === bin.name);

        if (event.shiftKey && this.lastSelectedIndex !== null) {
            const start = Math.min(this.lastSelectedIndex, currentIndex);
            const end = Math.max(this.lastSelectedIndex, currentIndex);
            for (let i = start; i <= end; i++) {
                this.itemMultiSelection.add((this.resources$.value[i] as Bin).name);
            }
        } else {
            if (this.itemMultiSelection.has(bin.name)) {
                this.itemMultiSelection.delete(bin.name);
            } else {
                this.itemMultiSelection.add(bin.name);
            }
            this.lastSelectedIndex = currentIndex;
        }
        this.cdr.markForCheck(); // Trigger change detection
    }

    toggleSelectAll() {
        if (this.allChecked && this.itemMultiSelection.size > 0) {
            this.itemMultiSelection.clear();
        } else {
            if (this.searchModeVisible == BinSectionContent.BIN) {
                this.resources$.value.map((bin) => this.itemMultiSelection.add((bin as Bin).name));
            }
        }
        this.cdr.markForCheck();
    }

    deleteSelection() {
        this.dialog.open(DeleteMultipleBinDialog, {
            ...DeleteMultipleBinDialog.dialogOptions,
            data: {
                bins: this.itemMultiSelection,
                onConfirm: () => {
                    this.pageIndex$.next(0);
                },
                onOpen: () => {
                    this.cdr.detectChanges();
                    this.itemMultiSelection = new Set();
                },
                onCancel: () => {
                    this.cdr.detectChanges();
                }
            }
        });
    }

    ngOnDestroy() {
        this.pageIndex$.complete();
        this.assetOwner$.complete();
        this.resources$.complete();
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    private extractUrlDetails() {
        const urlTree: UrlTree = this.router.parseUrl(this.router.url);
        const urlPathSegments: UrlSegment[] = urlTree.root.children['primary']?.segments;
        const isNestedFolder: boolean = urlPathSegments?.[0]?.path === 'folders';
        const parentId = urlPathSegments?.[1]?.path;
        return { isNestedFolder, parentId };
    }

    /**
     * Updates status related to pagination calculation
     */
    private updateBinsResults(res: pageResult) {
        const pageLimit = this.retrievePaginationLimit();
        const { bins, nextPageToken } = res;
        this.binsPagination.nextPageToken = nextPageToken;
        this.binsPagination.pageSize = pageLimit;
        const index = this.binsPagination.pageIndex;

        // Save new page results in cache. Avoid duplicates
        this.binsPagination.pagesCache[index] = res;

        // Combine all cached results into one list of assets for details navigation.
        const cachedResults: Bin[] = this.binsPagination.pagesCache.reduce<Bin[]>((segments, result) => {
            segments.push(...(result.bins || []));
            return segments;
        }, []);
        const portionStart = index * pageLimit;
        const portionEnd = portionStart + pageLimit;
        const portion = cachedResults.slice(portionStart, portionEnd);
        this.resultsLoading = false;
        this.resources$.next(portion);
        // We reached the last page, calculate the total number of results
        if (!nextPageToken) {
            const pagesCachedCount = this.binsPagination.pagesCache.length;
            const countUntilNow = (pagesCachedCount - 1) * this.binsPagination.pageSize;
            this.binsPagination.totalCount = countUntilNow + (bins?.length || 0);
        }
    }

    private updateClipsResults(res: pageResult) {
        const pageLimit = this.retrievePaginationLimit();
        const clips = res.assets as Clip[];
        const nextPageToken = res.nextPageToken;
        this.clipsPagination.nextPageToken = nextPageToken;
        this.clipsPagination.pageSize = pageLimit;
        const index = this.clipsPagination.pageIndex;

        // Save new page results in cache. Avoid duplicates
        this.clipsPagination.pagesCache[index] = res;

        // Combine all cached results into one list of assets for details navigation.
        const cachedResults: Clip[] = this.clipsPagination.pagesCache.reduce<Clip[]>((segments, result) => {
            segments.push(...((result.assets as Clip[]) || []));
            return segments;
        }, []);
        const portionStart = index * pageLimit;
        const portionEnd = portionStart + pageLimit;
        const portion = cachedResults.slice(portionStart, portionEnd);
        this.resultsLoading = false;
        this.resources$.next(portion);

        // We reached the last page, calculate the total number of results
        if (!nextPageToken) {
            const pagesCachedCount = this.clipsPagination.pagesCache.length;
            const countUntilNow = (pagesCachedCount - 1) * this.clipsPagination.pageSize;
            this.clipsPagination.totalCount = countUntilNow + (clips?.length || 0);
        }
    }

    private updateFoldersResults(res: Resource[]) {
        this.resultsLoading = false;
        this.resources$.next(res);
        this.foldersPagination.lastCursor = { ...this.foldersPagination.nextCursor } as Resource;
        this.foldersPagination.nextCursor = res[res.length - 1];
    }

    private resetPagination() {
        if (this.searchModeSelected == this.searchModeVisible && this.searchText) {
            this.resources$.next([]);
            this.pageIndex$.next(0);
        }

        const pageLimit = this.retrievePaginationLimit();
        this.binsPagination = this.paginationService.getEmptyPagination(pageLimit);
        this.clipsPagination = this.paginationService.getEmptyPagination(pageLimit);
        this.foldersPagination = {
            pageLimit,
            nextCursor: null,
            lastCursor: null,
            pageIndex: 0,
            totalCount: 0
        };
        this.clipBinFolderService.getMyFolders();
    }

    private processSearchResults(res: pageResult | Resource[] | null) {
        let result;
        switch (this.searchModeSelected) {
            case BinSectionContent.CLIP:
                result = res as pageResult;
                if (result.assets) {
                    this.searchModeVisible = BinSectionContent.CLIP;
                    this.updateClipsResults(result);
                } else {
                    this.searchModeVisible = BinSectionContent.BIN;
                    this.updateBinsResults(result);
                }
                break;
            case BinSectionContent.FOLDER:
                result = res as Resource[];
                this.searchModeVisible = BinSectionContent.FOLDER;
                this.updateFoldersResults(result);
                break;

            case BinSectionContent.BIN:
                this.searchModeVisible = BinSectionContent.BIN;
                result = res as pageResult;
                this.updateBinsResults(result);
                break;
        }
    }

    private getSearchChanged() {
        return this.searchSubject.pipe(
            map((value) => {
                if (!value || !value.length) {
                    this.searchText = null;
                    return null;
                }
                this.searchText = value;
                this.searchInputService.partialQuery$.next(value);
                this.itemMultiSelection.clear();
                this.folderUrlId = null;
                return value;
            }),
            debounceTime(SEARCH_DEBOUNCE),
            startWith(''),
            distinctUntilChanged()
        );
    }

    private registerKeydownListeners() {
        const onKeydown = (e: KeyboardEvent) => {
            if (e.key === 'Enter' && this.searchControl.value) {
                this.searchSubject.next(this.searchControl.value);
                this.onEnterPressed();
            }
        };

        if (!this.searchInput) return;
        this.searchInput.nativeElement.addEventListener('keydown', onKeydown, { capture: true });
        this.destroyed$.subscribe(() => {
            document.removeEventListener('keydown', onKeydown);
        });
    }

    private onEnterPressed() {
        this.searchInput?.nativeElement.blur();
        this.searchField?._textField.nativeElement.blur();
    }
}
